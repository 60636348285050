import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useDispatch } from "react-redux";
import { setIsFirstEntry, setAuth } from "../../redux/slices/auth.slice";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap"

const backendUrl = process.env.REACT_APP_PUBLIC_API_BACKEND;

interface IGlobalEtape3of3 {
  token: string | undefined;
}

const GlobalEtape3of3: React.FC<IGlobalEtape3of3> = ({ token }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState(false);
  const { t } = useTranslation();

  const [state, setState] = useState({
    isloading: false,
  });

  const handleOnClick = () => {
    setState((prevstate) => ({ ...prevstate, isloading: true }));

    dispatch(setIsFirstEntry(true));

    axios
      .post(
        `${backendUrl}/users/tokuser`,
        {},
        {
          headers: {
            authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        if (response.status === 200) {
          setTimeout(() => {
            dispatch(setAuth(response.data));
            navigate("/home");
          }, 2500);
        } else {
          setErrorMessage(true);
        }
      })
      .catch((error) => setErrorMessage(true));
  };

  return (
    <>
      {!state.isloading ? (
        <>
          <h2 className="text-start text-light text-center h4">Your account is activated!</h2>

          <Button
            type="button"
            className="signin__button signin__button--size w-100 border-0 p-2 rounded-5 mt-4"
            onClick={handleOnClick}
          >
            Buy your first crypto
          </Button>

          {errorMessage && (
            <div className="text-danger">
              ⚠ Sorry, an error has occurred ⚠
            </div>
          )}
        </>
      ) : (
        <>
          <Spinner animation="grow" size="sm" variant="light" />
          <Spinner animation="grow" size="sm" variant="light" />
          <Spinner animation="grow" size="sm" variant="light" />

          <Spinner animation="grow" variant="light" />
          {errorMessage && (
            <div className="text-danger">
              ⚠ Sorry, an error has occurred ⚠
            </div>
          )}
        </>
      )}
    </>
  );
};

export default GlobalEtape3of3;
