import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { IRootState } from "../../redux/store/store";
import security from "../../static/images/security.png";
import { useTranslation } from "react-i18next";

export const Thieves = () => {
  const { t } = useTranslation();

  const [isVisible1, setIsVisible1] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);
  const [isVisible3, setIsVisible3] = useState(false);

  const { colorFirst, colorTextSecond } = useSelector(
    ({ styleData }: IRootState) => styleData
  );

  const componentRef1 = useRef(null);
  const componentRef2 = useRef(null);
  const componentRef3 = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (componentRef1.current) {
        const componentTopOffset =
          (window.innerHeight -
            //@ts-ignore
            componentRef1.current.getBoundingClientRect().top) /
          window.innerHeight;

        const componentBottomOffset =
          (window.innerHeight -
            //@ts-ignore
            componentRef1.current.getBoundingClientRect().bottom) /
          window.innerHeight;

        setIsVisible1(componentBottomOffset < 0.8 && componentTopOffset > 0.3);
      }

      if (componentRef2.current) {
        const componentTopOffset =
          (window.innerHeight -
            //@ts-ignore
            componentRef2.current.getBoundingClientRect().top) /
          window.innerHeight;

        const componentBottomOffset =
          (window.innerHeight -
            //@ts-ignore
            componentRef2.current.getBoundingClientRect().bottom) /
          window.innerHeight;

        setIsVisible2(componentBottomOffset < 0.8 && componentTopOffset > 0.3);
      }

      if (componentRef3.current) {
        const componentTopOffset =
          (window.innerHeight -
            //@ts-ignore
            componentRef3.current.getBoundingClientRect().top) /
          window.innerHeight;

        const componentBottomOffset =
          (window.innerHeight -
            //@ts-ignore
            componentRef3.current.getBoundingClientRect().bottom) /
          window.innerHeight;

        setIsVisible3(componentBottomOffset < 0.8 && componentTopOffset > 0.3);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div
        className="row mx-auto px-2 my-5 pt-5"
        style={{ maxWidth: "1320px" }}
      >
        <div
          className={`col-12 col-md-7 col-lg-8 pt-4 order-1 order-md-0 d-flex flex-column justify-content-center animated-component ${isVisible2 ? "visible" : "hidden"}`}
          ref={componentRef2}
        >
          <p className="header__title--less fw-bold lh-1">Disappoint thieves</p>

          <p
            className="fs-5 col-10 col-md-7 lh-sm m-0 my-5"
            style={{ color: colorTextSecond }}
          >
            {t("personal.thieves.sub")}
          </p>

          <Link
            to="/"
            className="navbarCont__button fw-bold text-dark text-decoration-none px-4 py-3 text-center rounded-5 mx-auto mx-md-0"
            style={{
              width: "max-content",
              backgroundColor: colorFirst,
            }}
          >
            How keep your money safe
          </Link>
        </div>

        <div
          className={`col-8 col-sm-6 col-md-5 col-lg-4 text-end d-flex mx-auto align-items-center animated-component ${isVisible1 ? "visible" : "hidden"}`}
          ref={componentRef1}
        >
          <img
            src={security}
            alt="Lock illustration"
            style={{
              width: "100%",
            }}
          />
        </div>
      </div>

      <div
        className={`mx-auto row flex-column flex-md-row px-5 pb-5 mb-5 animated-component ${isVisible3 ? "visible" : "hidden"}`}
        style={{ maxWidth: "1320px" }}
        ref={componentRef3}
      >
        <div className="col-11 col-md-4 p-0 pe-3 mt-5 mt-md-0">
          <div
            className="d-flex justify-content-center align-items-center rounded-circle"
            style={{
              width: "56px",
              height: "56px",
              background: "rgba(22, 51, 0, .078)",
            }}
          >
            <svg
              width="24"
              height="24"
              fill="currentColor"
              focusable="false"
              viewBox="0 0 24 24"
            >
              <path
                fillRule="evenodd"
                d="M17.143 8.57h2.571a.86.86 0 0 1 .857.857v10.286A2.579 2.579 0 0 1 18 22.284H6a2.579 2.579 0 0 1-2.572-2.571V9.427a.86.86 0 0 1 .858-.857h2.571V6.856c0-2.829 2.314-5.143 5.143-5.143 2.829 0 5.143 2.314 5.143 5.143V8.57ZM12 3.427A3.439 3.439 0 0 0 8.57 6.856V8.57h6.857V6.856A3.439 3.439 0 0 0 12 3.427Zm6 17.143a.86.86 0 0 0 .857-.857v-9.429H5.143v9.429A.86.86 0 0 0 6 20.57h12Zm-6.857-1.716v-2.83c-.515-.256-.858-.813-.858-1.456A1.72 1.72 0 0 1 12 12.854a1.72 1.72 0 0 1 1.714 1.714c0 .6-.343 1.157-.857 1.457v2.829h-1.714Z"
                clipRule="evenodd"
              ></path>
            </svg>
          </div>

          <p
            className="fs-5 m-0 mt-3 ps-1 lh-1"
            style={{ color: colorTextSecond }}
          >
            {t("personal.thieves.step1")}
          </p>
        </div>

        <div className="col-11 col-md-4 p-0 pe-3 mt-5 mt-md-0">
          <div
            className="d-flex justify-content-center align-items-center rounded-circle"
            style={{
              width: "56px",
              height: "56px",
              background: "rgba(22, 51, 0, .078)",
            }}
          >
            <svg
              width="24"
              height="24"
              fill="currentColor"
              focusable="false"
              viewBox="0 0 24 24"
            >
              <path
                fillRule="evenodd"
                d="M9 4.284a2.571 2.571 0 0 1 2.571-2.571h6.858A2.571 2.571 0 0 1 21 4.284v15.429a2.571 2.571 0 0 1-2.571 2.571h-6.764a2.649 2.649 0 0 1-.094.002h-6A2.571 2.571 0 0 1 3 19.715v-6.853c0-.476.386-.862.861-.862h1.282v-1.285A3.429 3.429 0 0 1 9 7.313V4.284Zm1.714 3.754A3.429 3.429 0 0 1 12 10.714V12h1.281c.476 0 .862.386.862.862v6.853c0 .3-.052.587-.146.855h4.432a.857.857 0 0 0 .857-.857V4.284a.857.857 0 0 0-.857-.857h-1.83a.857.857 0 0 1-.742 1.286h-1.714a.857.857 0 0 1-.743-1.286h-1.829a.857.857 0 0 0-.857.857v3.754Zm.911 12.532a.857.857 0 0 0 .804-.855v-6H4.714v6c0 .473.384.857.857.857h5.143v-.002h.911ZM7.36 9.502a1.714 1.714 0 0 1 2.927 1.213V12H6.857v-1.285c0-.455.18-.891.502-1.213Zm.355 6.355H9.43v2.572H7.714v-2.572Z"
                clipRule="evenodd"
              ></path>
            </svg>
          </div>

          <p
            className="fs-5 m-0 mt-3 ps-1 lh-1"
            style={{ color: colorTextSecond }}
          >
            {t("personal.thieves.step2")}
          </p>
        </div>

        <div className="col-11 col-md-4 p-0 pe-3 mt-5 mt-md-0">
          <div
            className="d-flex justify-content-center align-items-center rounded-circle px-3"
            style={{
              width: "56px",
              height: "56px",
              background: "rgba(22, 51, 0, .078)",
            }}
          >
            <svg
              width="24"
              height="24"
              fill="currentColor"
              focusable="false"
              viewBox="0 0 24 24"
            >
              <path
                fillRule="evenodd"
                d="M12.905 2.658a1.304 1.304 0 0 0-1.373-.015L2.42 8.156a.87.87 0 0 0 .45 1.614h1.304v10.002h-1.74v1.739h19.131v-1.74h-1.739v-10h1.304V9.77a.869.869 0 0 0 .466-1.604l-8.69-5.508Zm5.23 5.373-5.933-3.76-6.216 3.76h12.149Zm-5.266 1.74h5.218v10H12.87v-10Zm-6.956 0v10h5.217v-10H5.913Z"
                clipRule="evenodd"
              ></path>
            </svg>
          </div>

          <p
            className="fs-5 m-0 mt-3 px-1 lh-1"
            style={{ color: colorTextSecond }}
          >
            {t("personal.thieves.step3")}
          </p>
        </div>
      </div>
    </>
  );
};
