import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setPaymentType,
  setResetOrder,
} from "../../../redux/slices/order.slice";
import stepBackArrow from "../../../static/icons/app/trade/stepBackArrow.svg";

import * as yup from "yup";
import { Field, Form, Formik } from "formik";
import { IRootState } from "../../../redux/store/store";
import { CardPayment } from "./CardPayment";
import { BankTransfer } from "./BankTransfer";
import { useTranslation } from "react-i18next";
import { OverlayTrigger, ProgressBar } from "react-bootstrap";
import RenderTooltip from "../../RenderTooltip";
import axios from "axios";

interface IStepTrade3 {
  setStep: (value: number) => void;
}

const backendUrl = process.env.REACT_APP_PUBLIC_API_BACKEND;

export const StepTrade3: React.FC<IStepTrade3> = ({ setStep }) => {
  const { t } = useTranslation();

  const {
    paymentType,
    orderReference,
    bankIban,
    bankBic,
    inputAmountBuy,
    inputCurrencyBuy,
    stockOrderId,
  } = useSelector(({ orderData }: IRootState) => orderData);

  const { token } = useSelector(
    ({ authData }: IRootState) => authData.fetchAuth
  );

  const dispatch = useDispatch();

  const handleBack = () => {
    dispatch(setPaymentType(""));

    setStep(1);
  };

  const handleConfirm = () => {
    const objectForConfirm = {
      idOrders: stockOrderId,
    };

    const HeaderPost = {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    axios
      .post(`${backendUrl}/order/fundssent`, objectForConfirm, HeaderPost)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          dispatch(setResetOrder());
          setStep(3);
        }
      })
      .catch((error) => console.log(error.response.data.message));
  };

  return (
    <div
      className="app__card rounded-4 px-4 py-2 pt-xl-4 pb-xl-5 mb-3"
    >
      <button
        className="bg-transparent border-0 app__payment-step3--back d-flex align-items-center p-0"
        style={{
          color: "#989898",
        }}
        onClick={handleBack}
      >
        <img src={stepBackArrow} alt="stepBackArrow" height={13} />{" "}
        <span className="ms-1">{t("app.trade.back")}</span>
      </button>

      <div className="d-flex align-items-center justify-content-between mt-4">
        <span className="app__payment-step--title">
          {t("app.trade.step.2")}
        </span>
        <div
          style={{
            width: "88%",
          }}
        >
          <ProgressBar
            now={66}
            label={55}
            visuallyHidden
            variant="#FFF"
            style={{
              height: "2.6px",
              background: "#D9D9D9",
            }}
          />
        </div>
      </div>

      <div className="mb-4">
        {/* {paymentType === "Card" && <CardPayment />}

        {paymentType === "BankTransfer" && <BankTransfer />} */}

        <p className="app__payment--method mt-3 mt-md-4">
          {t("app.trade.order.title")}
        </p>

        <div className="d-flex">
          <span
            className="m-0 mt-3"
            style={{
              color: "#989898",
            }}
          >
            {t("app.trade.order.amount")}{" "}
          </span>

          <span className="m-0 ms-2 mt-3">{inputAmountBuy}{" "}{inputCurrencyBuy.maj}</span>
        </div>

        <div className="d-flex mt-2">
          <span
            className="m-0"
            style={{
              color: "#989898",
            }}
          >
            {t("app.trade.order.ref")}{" "}
          </span>

          <span className="m-0 ms-2">
            {orderReference}{" "}
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={RenderTooltip}
            >
              <span
                className=""
                onClick={() => {
                  navigator.clipboard.writeText(orderReference);
                }}
              >
                <svg
                  style={{
                    color: "rgb(110, 64, 201)",
                    width: 14,
                    height: 14,
                  }}
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 115.77 122.88"
                >
                  <g>
                    <path
                      className="st0"
                      d="M89.62,13.96v7.73h12.19h0.01v0.02c3.85,0.01,7.34,1.57,9.86,4.1c2.5,2.51,4.06,5.98,4.07,9.82h0.02v0.02 v73.27v0.01h-0.02c-0.01,3.84-1.57,7.33-4.1,9.86c-2.51,2.5-5.98,4.06-9.82,4.07v0.02h-0.02h-61.7H40.1v-0.02 c-3.84-0.01-7.34-1.57-9.86-4.1c-2.5-2.51-4.06-5.98-4.07-9.82h-0.02v-0.02V92.51H13.96h-0.01v-0.02c-3.84-0.01-7.34-1.57-9.86-4.1 c-2.5-2.51-4.06-5.98-4.07-9.82H0v-0.02V13.96v-0.01h0.02c0.01-3.85,1.58-7.34,4.1-9.86c2.51-2.5,5.98-4.06,9.82-4.07V0h0.02h61.7 h0.01v0.02c3.85,0.01,7.34,1.57,9.86,4.1c2.5,2.51,4.06,5.98,4.07,9.82h0.02V13.96L89.62,13.96z M79.04,21.69v-7.73v-0.02h0.02 c0-0.91-0.39-1.75-1.01-2.37c-0.61-0.61-1.46-1-2.37-1v0.02h-0.01h-61.7h-0.02v-0.02c-0.91,0-1.75,0.39-2.37,1.01 c-0.61,0.61-1,1.46-1,2.37h0.02v0.01v64.59v0.02h-0.02c0,0.91,0.39,1.75,1.01,2.37c0.61,0.61,1.46,1,2.37,1v-0.02h0.01h12.19V35.65 v-0.01h0.02c0.01-3.85,1.58-7.34,4.1-9.86c2.51-2.5,5.98-4.06,9.82-4.07v-0.02h0.02H79.04L79.04,21.69z M105.18,108.92V35.65v-0.02 h0.02c0-0.91-0.39-1.75-1.01-2.37c-0.61-0.61-1.46-1-2.37-1v0.02h-0.01h-61.7h-0.02v-0.02c-0.91,0-1.75,0.39-2.37,1.01 c-0.61,0.61-1,1.46-1,2.37h0.02v0.01v73.27v0.02h-0.02c0,0.91,0.39,1.75,1.01,2.37c0.61,0.61,1.46,1,2.37,1v-0.02h0.01h61.7h0.02 v0.02c0.91,0,1.75-0.39,2.37-1.01c0.61-0.61,1-1.46,1-2.37h-0.02V108.92L105.18,108.92z"
                      fill="#6e40c9"
                    ></path>
                  </g>
                </svg>
              </span>
            </OverlayTrigger>
          </span>
        </div>

        <div className="d-flex mt-2">
          <span
            className="m-0"
            style={{
              color: "#989898",
            }}
          >
            {t("app.trade.order.recipient")}{" "}
          </span>

          <span className="m-0 ms-2">
            CornerSwap sp zoo{" "}
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={RenderTooltip}
            >
              <span
                className=""
                onClick={() => {
                  navigator.clipboard.writeText("CornerSwap sp zoo");
                }}
              >
                <svg
                  style={{
                    color: "rgb(110, 64, 201)",
                    width: 14,
                    height: 14,
                  }}
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 115.77 122.88"
                >
                  <g>
                    <path
                      className="st0"
                      d="M89.62,13.96v7.73h12.19h0.01v0.02c3.85,0.01,7.34,1.57,9.86,4.1c2.5,2.51,4.06,5.98,4.07,9.82h0.02v0.02 v73.27v0.01h-0.02c-0.01,3.84-1.57,7.33-4.1,9.86c-2.51,2.5-5.98,4.06-9.82,4.07v0.02h-0.02h-61.7H40.1v-0.02 c-3.84-0.01-7.34-1.57-9.86-4.1c-2.5-2.51-4.06-5.98-4.07-9.82h-0.02v-0.02V92.51H13.96h-0.01v-0.02c-3.84-0.01-7.34-1.57-9.86-4.1 c-2.5-2.51-4.06-5.98-4.07-9.82H0v-0.02V13.96v-0.01h0.02c0.01-3.85,1.58-7.34,4.1-9.86c2.51-2.5,5.98-4.06,9.82-4.07V0h0.02h61.7 h0.01v0.02c3.85,0.01,7.34,1.57,9.86,4.1c2.5,2.51,4.06,5.98,4.07,9.82h0.02V13.96L89.62,13.96z M79.04,21.69v-7.73v-0.02h0.02 c0-0.91-0.39-1.75-1.01-2.37c-0.61-0.61-1.46-1-2.37-1v0.02h-0.01h-61.7h-0.02v-0.02c-0.91,0-1.75,0.39-2.37,1.01 c-0.61,0.61-1,1.46-1,2.37h0.02v0.01v64.59v0.02h-0.02c0,0.91,0.39,1.75,1.01,2.37c0.61,0.61,1.46,1,2.37,1v-0.02h0.01h12.19V35.65 v-0.01h0.02c0.01-3.85,1.58-7.34,4.1-9.86c2.51-2.5,5.98-4.06,9.82-4.07v-0.02h0.02H79.04L79.04,21.69z M105.18,108.92V35.65v-0.02 h0.02c0-0.91-0.39-1.75-1.01-2.37c-0.61-0.61-1.46-1-2.37-1v0.02h-0.01h-61.7h-0.02v-0.02c-0.91,0-1.75,0.39-2.37,1.01 c-0.61,0.61-1,1.46-1,2.37h0.02v0.01v73.27v0.02h-0.02c0,0.91,0.39,1.75,1.01,2.37c0.61,0.61,1.46,1,2.37,1v-0.02h0.01h61.7h0.02 v0.02c0.91,0,1.75-0.39,2.37-1.01c0.61-0.61,1-1.46,1-2.37h-0.02V108.92L105.18,108.92z"
                      fill="#6e40c9"
                    ></path>
                  </g>
                </svg>
              </span>
            </OverlayTrigger>
          </span>
        </div>

        <div className="d-flex mt-2">
          <span
            className="m-0"
            style={{
              color: "#989898",
            }}
          >
            {t("app.trade.order.adress")}{" "}
          </span>

          <span className="m-0 ms-2">
            22B Bartycka Street, Office 21A, 00-716 Warsaw, Poland{" "}
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={RenderTooltip}
            >
              <span
                className=""
                onClick={() => {
                  navigator.clipboard.writeText(
                    "22B Bartycka Street, Office 21A, 00-716 Warsaw, Poland"
                  );
                }}
              >
                <svg
                  style={{
                    color: "rgb(110, 64, 201)",
                    width: 14,
                    height: 14,
                  }}
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 115.77 122.88"
                >
                  <g>
                    <path
                      className="st0"
                      d="M89.62,13.96v7.73h12.19h0.01v0.02c3.85,0.01,7.34,1.57,9.86,4.1c2.5,2.51,4.06,5.98,4.07,9.82h0.02v0.02 v73.27v0.01h-0.02c-0.01,3.84-1.57,7.33-4.1,9.86c-2.51,2.5-5.98,4.06-9.82,4.07v0.02h-0.02h-61.7H40.1v-0.02 c-3.84-0.01-7.34-1.57-9.86-4.1c-2.5-2.51-4.06-5.98-4.07-9.82h-0.02v-0.02V92.51H13.96h-0.01v-0.02c-3.84-0.01-7.34-1.57-9.86-4.1 c-2.5-2.51-4.06-5.98-4.07-9.82H0v-0.02V13.96v-0.01h0.02c0.01-3.85,1.58-7.34,4.1-9.86c2.51-2.5,5.98-4.06,9.82-4.07V0h0.02h61.7 h0.01v0.02c3.85,0.01,7.34,1.57,9.86,4.1c2.5,2.51,4.06,5.98,4.07,9.82h0.02V13.96L89.62,13.96z M79.04,21.69v-7.73v-0.02h0.02 c0-0.91-0.39-1.75-1.01-2.37c-0.61-0.61-1.46-1-2.37-1v0.02h-0.01h-61.7h-0.02v-0.02c-0.91,0-1.75,0.39-2.37,1.01 c-0.61,0.61-1,1.46-1,2.37h0.02v0.01v64.59v0.02h-0.02c0,0.91,0.39,1.75,1.01,2.37c0.61,0.61,1.46,1,2.37,1v-0.02h0.01h12.19V35.65 v-0.01h0.02c0.01-3.85,1.58-7.34,4.1-9.86c2.51-2.5,5.98-4.06,9.82-4.07v-0.02h0.02H79.04L79.04,21.69z M105.18,108.92V35.65v-0.02 h0.02c0-0.91-0.39-1.75-1.01-2.37c-0.61-0.61-1.46-1-2.37-1v0.02h-0.01h-61.7h-0.02v-0.02c-0.91,0-1.75,0.39-2.37,1.01 c-0.61,0.61-1,1.46-1,2.37h0.02v0.01v73.27v0.02h-0.02c0,0.91,0.39,1.75,1.01,2.37c0.61,0.61,1.46,1,2.37,1v-0.02h0.01h61.7h0.02 v0.02c0.91,0,1.75-0.39,2.37-1.01c0.61-0.61,1-1.46,1-2.37h-0.02V108.92L105.18,108.92z"
                      fill="#6e40c9"
                    ></path>
                  </g>
                </svg>
              </span>
            </OverlayTrigger>
          </span>
        </div>

        <div className="d-flex mt-2">
          <span
            className="m-0"
            style={{
              color: "#989898",
            }}
          >
            {t("app.trade.order.sepaIban")}{" "}
          </span>

          <span className="m-0 ms-2">
            {bankIban}{" "}
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={RenderTooltip}
            >
              <span
                className=""
                onClick={() => {
                  navigator.clipboard.writeText(bankIban);
                }}
              >
                <svg
                  style={{
                    color: "rgb(110, 64, 201)",
                    width: 14,
                    height: 14,
                  }}
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 115.77 122.88"
                >
                  <g>
                    <path
                      className="st0"
                      d="M89.62,13.96v7.73h12.19h0.01v0.02c3.85,0.01,7.34,1.57,9.86,4.1c2.5,2.51,4.06,5.98,4.07,9.82h0.02v0.02 v73.27v0.01h-0.02c-0.01,3.84-1.57,7.33-4.1,9.86c-2.51,2.5-5.98,4.06-9.82,4.07v0.02h-0.02h-61.7H40.1v-0.02 c-3.84-0.01-7.34-1.57-9.86-4.1c-2.5-2.51-4.06-5.98-4.07-9.82h-0.02v-0.02V92.51H13.96h-0.01v-0.02c-3.84-0.01-7.34-1.57-9.86-4.1 c-2.5-2.51-4.06-5.98-4.07-9.82H0v-0.02V13.96v-0.01h0.02c0.01-3.85,1.58-7.34,4.1-9.86c2.51-2.5,5.98-4.06,9.82-4.07V0h0.02h61.7 h0.01v0.02c3.85,0.01,7.34,1.57,9.86,4.1c2.5,2.51,4.06,5.98,4.07,9.82h0.02V13.96L89.62,13.96z M79.04,21.69v-7.73v-0.02h0.02 c0-0.91-0.39-1.75-1.01-2.37c-0.61-0.61-1.46-1-2.37-1v0.02h-0.01h-61.7h-0.02v-0.02c-0.91,0-1.75,0.39-2.37,1.01 c-0.61,0.61-1,1.46-1,2.37h0.02v0.01v64.59v0.02h-0.02c0,0.91,0.39,1.75,1.01,2.37c0.61,0.61,1.46,1,2.37,1v-0.02h0.01h12.19V35.65 v-0.01h0.02c0.01-3.85,1.58-7.34,4.1-9.86c2.51-2.5,5.98-4.06,9.82-4.07v-0.02h0.02H79.04L79.04,21.69z M105.18,108.92V35.65v-0.02 h0.02c0-0.91-0.39-1.75-1.01-2.37c-0.61-0.61-1.46-1-2.37-1v0.02h-0.01h-61.7h-0.02v-0.02c-0.91,0-1.75,0.39-2.37,1.01 c-0.61,0.61-1,1.46-1,2.37h0.02v0.01v73.27v0.02h-0.02c0,0.91,0.39,1.75,1.01,2.37c0.61,0.61,1.46,1,2.37,1v-0.02h0.01h61.7h0.02 v0.02c0.91,0,1.75-0.39,2.37-1.01c0.61-0.61,1-1.46,1-2.37h-0.02V108.92L105.18,108.92z"
                      fill="#6e40c9"
                    ></path>
                  </g>
                </svg>
              </span>
            </OverlayTrigger>
          </span>
        </div>

        <div className="d-flex mt-2">
          <span
            className="m-0"
            style={{
              color: "#989898",
            }}
          >
            {t("app.trade.order.sepaBic")}{" "}
          </span>

          <span className="m-0 ms-2">
            {bankBic}{" "}
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={RenderTooltip}
            >
              <span
                className=""
                onClick={() => {
                  navigator.clipboard.writeText(bankBic);
                }}
              >
                <svg
                  style={{
                    color: "rgb(110, 64, 201)",
                    width: 14,
                    height: 14,
                  }}
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 115.77 122.88"
                >
                  <g>
                    <path
                      className="st0"
                      d="M89.62,13.96v7.73h12.19h0.01v0.02c3.85,0.01,7.34,1.57,9.86,4.1c2.5,2.51,4.06,5.98,4.07,9.82h0.02v0.02 v73.27v0.01h-0.02c-0.01,3.84-1.57,7.33-4.1,9.86c-2.51,2.5-5.98,4.06-9.82,4.07v0.02h-0.02h-61.7H40.1v-0.02 c-3.84-0.01-7.34-1.57-9.86-4.1c-2.5-2.51-4.06-5.98-4.07-9.82h-0.02v-0.02V92.51H13.96h-0.01v-0.02c-3.84-0.01-7.34-1.57-9.86-4.1 c-2.5-2.51-4.06-5.98-4.07-9.82H0v-0.02V13.96v-0.01h0.02c0.01-3.85,1.58-7.34,4.1-9.86c2.51-2.5,5.98-4.06,9.82-4.07V0h0.02h61.7 h0.01v0.02c3.85,0.01,7.34,1.57,9.86,4.1c2.5,2.51,4.06,5.98,4.07,9.82h0.02V13.96L89.62,13.96z M79.04,21.69v-7.73v-0.02h0.02 c0-0.91-0.39-1.75-1.01-2.37c-0.61-0.61-1.46-1-2.37-1v0.02h-0.01h-61.7h-0.02v-0.02c-0.91,0-1.75,0.39-2.37,1.01 c-0.61,0.61-1,1.46-1,2.37h0.02v0.01v64.59v0.02h-0.02c0,0.91,0.39,1.75,1.01,2.37c0.61,0.61,1.46,1,2.37,1v-0.02h0.01h12.19V35.65 v-0.01h0.02c0.01-3.85,1.58-7.34,4.1-9.86c2.51-2.5,5.98-4.06,9.82-4.07v-0.02h0.02H79.04L79.04,21.69z M105.18,108.92V35.65v-0.02 h0.02c0-0.91-0.39-1.75-1.01-2.37c-0.61-0.61-1.46-1-2.37-1v0.02h-0.01h-61.7h-0.02v-0.02c-0.91,0-1.75,0.39-2.37,1.01 c-0.61,0.61-1,1.46-1,2.37h0.02v0.01v73.27v0.02h-0.02c0,0.91,0.39,1.75,1.01,2.37c0.61,0.61,1.46,1,2.37,1v-0.02h0.01h61.7h0.02 v0.02c0.91,0,1.75-0.39,2.37-1.01c0.61-0.61,1-1.46,1-2.37h-0.02V108.92L105.18,108.92z"
                      fill="#6e40c9"
                    ></path>
                  </g>
                </svg>
              </span>
            </OverlayTrigger>
          </span>
        </div>

        <div className="w-100 p-0 d-flex justify-content-end mt-5">
          <button
            className="app__payment-butto px-3 py-1 text-white d-flex justify-content-center align-items-center border-0 rounded-5"
            style={{
              background: "#DBB1F6",
            }}
            onClick={handleConfirm}
          >
            {/* {t("app.trade.order.button")} */}
            Yes, I make the bank transfer.
          </button>
        </div>
      </div>
    </div>
  );
};
