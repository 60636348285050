import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import { IRootState } from "../../redux/store/store";

const backendUrl = process.env.REACT_APP_PUBLIC_API_BACKEND;

interface ICurrencies {
  maj: string;
  min: string;
  quote: string;
  name: string;
  symbol: string;
}

interface IBuyBody {
  currencies: ICurrencies[];
  handleListCrypto: (value: string) => void;
}

export const BuyBody: React.FC<IBuyBody> = ({
  currencies,
  handleListCrypto,
}) => {
  const { t } = useTranslation();

  const [deviceSel, setDeviceSel] = useState(currencies[0]);
  const [search, setSearch] = useState("");
  const [change, setChange] = useState("0");
  const [currencySel, setCurrencySel] = useState("BTC");
  const [amountSel, setAmountSel] = useState("50");
  const [totalFees, setTotalFees] = useState("...");
  const [searchCrypto, setSearchCrypto] = useState(false);
  const [searchLanguage, setSearchLanguage] = useState(false);
  const [loader, setLoader] = useState(false);
  const [filteredListByCrypto, setFilteredListByCrypto] = useState({});

  const dropdownRef = useRef(null);
  const dropdownRefLng = useRef(null);
  const buttonRef = useRef(null);
  const buttonRefLng = useRef(null);

  const listByCrypto = useSelector(
    ({ orderData }: IRootState) => orderData.listCryptocurrency
  );
  const { colorTextSecond } = useSelector(
    ({ styleData }: IRootState) => styleData
  );

  const handleExchangeData = () => {
    setLoader(true);

    const objectToPost = {
      input: { currency: deviceSel.maj, amount: amountSel.replace(/,/g, ".") },
      output: { currency: currencySel },
      blockchain: "eth",
      dir: "fiat_to_crypto",
    };

    axios
      .post(`${backendUrl}/quotes/buy`, objectToPost)
      .then((response: any) => {
        if (response.status === 201) {
          const quotesData = response?.data?.quotes;

          setChange(quotesData.output.amount);
          setTotalFees(quotesData.response?.taux);
          setLoader(false);
        }
      })
      .catch((error) => console.log("Error: ", error));
  };

  useEffect(() => {
    setLoader(true);

    const timer = setTimeout(() => {
      handleExchangeData();
    }, 1500);

    return () => clearTimeout(timer);
  }, [deviceSel, currencySel, amountSel]);

  useEffect(() => {
    if (search === "") {
      setFilteredListByCrypto(listByCrypto);
    } else {
      const searchUpper = search.toUpperCase();
      const searchCapitalized =
        search.charAt(0).toUpperCase() + search.slice(1).toLowerCase();
      const filtered = Object.values(listByCrypto).filter(
        (item) =>
          // @ts-ignore
          item?.search?.startsWith(searchUpper) ||
          //@ts-ignore
          item?.name?.startsWith(searchCapitalized)
      );
      setFilteredListByCrypto(filtered);
    }
  }, [search, listByCrypto]);

  useEffect(() => {
    handleListCrypto(deviceSel.quote);

    function handleClickOutside(event: { target: any }) {
      const dropResult =
        dropdownRef.current &&
        //@ts-ignore
        !dropdownRef.current.contains(event.target) &&
        //@ts-ignore
        !buttonRef.current.contains(event.target);

      const dropResultLng =
        dropdownRefLng.current &&
        //@ts-ignore
        !dropdownRefLng.current.contains(event.target) &&
        //@ts-ignore
        !buttonRefLng.current.contains(event.target);

      dropResult && setSearchCrypto(false);
      dropResultLng && setSearchLanguage(false);
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const numberValue = e.target.value.slice(1).replace(/,/g, "");

    !isNaN(+numberValue) && setAmountSel(numberValue);
  };

  return (
    <div className="w-100">
      <div className="bg-white py-2 px-3 rounded-4">
        <p
          className="p-0 ps-1 m-0"
          style={{ color: colorTextSecond, fontSize: "12px" }}
        >
          Amount
        </p>

        <div className="d-flex align-items-center">
          <div className="d-flex col-4">
            <div className="position-relative d-flex justify-content-center align-items-center">
              {" "}
              <Dropdown>
                <Dropdown.Toggle
                  ref={buttonRefLng}
                  variant="lg"
                  id="dropdown-basic"
                  className="text-black fw-bold my-auto p-0"
                  style={{
                    minWidth: "100%",
                  }}
                  onClick={() => setSearchLanguage(!searchLanguage)}
                >
                  <div className="d-flex exchange__select--direction justify-content-center align-items-center">
                    <div className="d-flex justify-content-center align-items-center">
                      <img
                        src={`https://landing.fra1.cdn.digitaloceanspaces.com/Flag-png/${deviceSel?.min}.png`}
                        alt=""
                        height="24px"
                      />
                      <div className="ms-2" style={{ fontSize: "22px"}}>
                        {deviceSel?.maj?.toUpperCase()}
                      </div>
                    </div>

                    <img src="https://assets.revolut.com/assets/icons/ChevronDown.svg" alt="ChevronDown" />
                  </div>
                </Dropdown.Toggle>

                {searchLanguage && (
                  <div
                    ref={dropdownRefLng}
                    className="exchangeDiv__search bg-white py-2 mt-3 position-absolute end-0"
                    style={{
                      zIndex: 1000,
                      width: "250%",
                      borderRadius: "10px",
                      boxShadow: "0px 0px 5px rgb(184, 184, 184)",
                    }}
                    onClick={() => setSearchLanguage(!searchLanguage)}
                  >
                    <div
                      style={{
                        height: "250px",
                        overflowY: "auto",
                        overscrollBehavior: "contain",
                      }}
                    >
                      {currencies?.map((currency: ICurrencies) => (
                        <div
                          className="btn m-0 p-0 border-0"
                          onClick={() => {
                            setDeviceSel(currency);
                            handleListCrypto(currency.quote);
                          }}
                          key={currency?.min}
                        >
                          <div className="d-flex justify-content-start align-items-center p-1 list-lng-card ps-3">
                            <img
                              src={`https://landing.fra1.cdn.digitaloceanspaces.com/Flag-png/${currency?.min}.png`}
                              alt=""
                              height="24px"
                            />
                            <div
                              className="ms-3 p-2"
                              style={{
                                fontSize: "18px",
                                fontWeight: "500",
                              }}
                            >
                              {currency?.maj?.toUpperCase()}
                            </div>
                            <div
                              style={{
                                fontSize: "12px",
                                fontWeight: "400",
                              }}
                            >
                              {currency?.name}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </Dropdown>
            </div>
          </div>

          <div className="exchangeInpDivLeft rounded-5 p-0">
            <input
              className="exchangeInpInput fw-bold fs-4 text-end"
              inputMode="decimal"
              value={deviceSel.symbol + (+amountSel).toLocaleString()}
              onChange={handleChangeInput}
            />
          </div>
        </div>
      </div>

      <div className="bg-white py-2 px-3 rounded-4 mt-2">
        <p
          className="p-0 ps-1 m-0"
          style={{ color: colorTextSecond, fontSize: "12px" }}
        >
          Converted to
        </p>

        <div className="exchangeInpDiv">
          <div className="d-flex col-4">
            <div className="position-relative d-flex justify-content-center align-items-center">
              {" "}
              <Dropdown>
                <Dropdown.Toggle
                  ref={buttonRef}
                  variant="lg"
                  id="dropdown-basic"
                  className="text-black my-auto fw-bold p-0"
                  style={{
                    minWidth: "100%",
                  }}
                  onClick={() => setSearchCrypto(!searchCrypto)}
                >
                  <div className="d-flex exchange__select--direction align-items-center justify-content-center">
                    <div className=" d-flex align-items-center justify-content-center">
                      <img
                        src={`https://assets.kraken.com/marketing/web/icons/sym-${currencySel?.toLocaleLowerCase()}_colored.svg`}
                        alt="logo"
                        style={{ height: "24px" }}
                        className="me-2"
                      />
                      <span style={{ fontSize: "22px" }}>{currencySel}</span>
                    </div>
                    
                    <img src="https://assets.revolut.com/assets/icons/ChevronDown.svg" alt="ChevronDown" />
                  </div>
                </Dropdown.Toggle>{" "}

                {searchCrypto && (
                  <div
                    ref={dropdownRef}
                    className="exchangeDiv__search bg-white pt-3 mt-3 rounded-4"
                    style={{
                      zIndex: 1000,
                      boxShadow: "0px 0px 5px rgb(184, 184, 184)",
                    }}
                  >
                    <div
                      className="mx-auto bg-search rounded d-flex align-items-center mb-2"
                      style={{ width: "90%" }}
                    >
                      <div className="py-2 ps-3 pe-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          className="bi bi-search"
                          viewBox="0 0 16 16"
                        >
                          <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                        </svg>
                      </div>
                      <input
                        className="input-search border-0"
                        type="text"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        style={{
                          backgroundColor: "transparent",
                          fontSize: "16px",
                          paddingLeft: "10px!important",
                          textTransform: "uppercase",
                          width: "75%",
                        }}
                        placeholder=""
                        autoFocus
                      />
                    </div>
                    <div
                      style={{
                        height: "250px",
                        overflowY: "auto",
                        overscrollBehavior: "contain",
                      }}
                    >
                      {Object.values(filteredListByCrypto).map(
                        (cryptoInfo, index) => {
                          //@ts-ignore
                          let base = cryptoInfo?.base;
                          if (
                            base &&
                            base.startsWith("X") &&
                            base !== "XCN" &&
                            base !== "XRT" &&
                            base !== "XTZ"
                          ) {
                            base = base.slice(1);
                          }
                          if (base && base.startsWith("Z")) {
                            return null;
                          }
                          if (base === "XBT") {
                            base = "BTC";
                          }
                          if (
                            base === "UST" ||
                            base === "TVK" ||
                            base === "NANO" ||
                            base === "ETHW"
                          ) {
                            return null;
                          }
                          return (
                            <div
                              key={index}
                              className=" p-1 my-1 item-list-crypto"
                              onClick={() => {
                                //@ts-ignore
                                setCurrencySel(cryptoInfo?.base);
                                setSearchCrypto(false);
                              }}
                            >
                              <div className="d-flex align-items-center justify-content-between py-1">
                                <div className="d-flex">

                                  <div className="ms-3 d-flex align-items-center">
                                    <img
                                      //@ts-ignore
                                      src={`https://assets.kraken.com/marketing/web/icons/sym-${cryptoInfo?.base?.toLowerCase()}_colored.svg`}
                                      alt="logo"
                                      style={{ height: "25px" }}
                                    />
                                  </div>

                                  <div
                                    className="ms-2 d-flex align-items-center"
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {(cryptoInfo as { base: string })?.base}
                                  </div>
                                  <div
                                    className="ms-2 text-start d-flex align-items-center"
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "400",
                                    }}
                                  >
                                    {(cryptoInfo as { name: string })?.name}
                                  </div>
                                </div>

                                <div
                                  className="mx-2 border-dark bg-grey text-black rounded px-1 d-flex align-items-center"
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "600",
                                  }}
                                >
                                  {(cryptoInfo as { type: string })?.type}
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}
              </Dropdown>
            </div>
          </div>

          <div className="exchangeInpDivLeft">
            <input
              className="exchangeInpInput fw-bold fs-4 text-end"
              inputMode="decimal"
              value={loader || +change === 0 ? "..." : (+change).toFixed(8)}
              disabled={true}
            />
          </div>
        </div>
      </div>

      <div className="bg-white rounded-4 mt-2 py-2 px-3">
        <div className="d-flex justify-content-between align-items-center fw-bold">
          <span style={{ color: colorTextSecond, fontSize: "12px" }}>
            Our current rate
          </span>

          <span>
            1 {currencySel} = {deviceSel.symbol}
            {loader ? "..." : (+totalFees).toLocaleString()}
          </span>
        </div>

        <div className="d-flex justify-content-between align-items-center mt-3 fw-bold">
          <span style={{ color: colorTextSecond, fontSize: "12px" }}>
            No fees
          </span>

          <span>{deviceSel.symbol}0.00</span>
        </div>
      </div>
    </div>
  );
};
