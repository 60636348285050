import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../../../redux/store/store";
import axios from "axios";
import {
  setListCryptocurrency,
  setOperationType,
} from "../../../../redux/slices/order.slice";
import { BuyBodyHome } from "./BuyBodyHome";
import { Link } from "react-router-dom";
import { SellBodyHome } from "./SellBodyHome";
import { useTranslation } from "react-i18next"

const backendUrl = process.env.REACT_APP_PUBLIC_API_BACKEND;

const currencies = [
  { maj: "EUR", min: "eur", quote: "ZEUR", name: "Euro", symbol: "€" },
  {
    maj: "USD",
    min: "usd",
    quote: "ZUSD",
    name: "United States Dollar",
    symbol: "$",
  },
  { maj: "JPY", min: "jpy", quote: "ZJPY", name: "Japanese Yen", symbol: "¥" },
  {
    maj: "AUD",
    min: "aud",
    quote: "ZAUD",
    name: "Australian Dollar",
    symbol: "$",
  },
  {
    maj: "CAD",
    min: "cad",
    quote: "ZCAD",
    name: "Canadian Dollar",
    symbol: "$",
  },
  { maj: "CHF", min: "chf", quote: "CHF", name: "Swiss Franc", symbol: "₣" },
];

export const ExchangeHome = () => {
  const { t } = useTranslation();

  const { operationType } = useSelector(
    ({ orderData }: IRootState) => orderData
  );

  const dispatch = useDispatch();

  const handleListCrypto = (value: string = "ZEUR") => {
    const objectToPost = {
      input: value,
    };

    axios
      .post(`${backendUrl}/pb/pairsByDevice`, objectToPost)
      .then(
        (response: any) =>
          response.status === 200 &&
          dispatch(setListCryptocurrency(response?.data))
      )
      .catch(function (error: any) {
        console.log("error :", error);
      });
  };

  useEffect(() => {
    handleListCrypto();
  }, []);

  return (
    <div>
      <p className="app__home-subtitle m-0 ps-3 mt-xl-2">{t("app.home.exchange.title")}</p>

      <div
        className="app__card rounded-4 p-3 py-xl-4 my-3 my-xl-2"
      >
        <div
          className="rounded-5 d-flex justify-content-between align-items-center"
          style={{
            background: "#E8ECF5",
            padding: "2px",
            width: "160px",
          }}
        >
          <button
            className="fw-bold text-white rounded-5 p-0 border-0"
            style={{
              background: operationType === "Buy" ? "#D8AFF3" : "transparent",
              width: "48%",
            }}
            onClick={() => dispatch(setOperationType("Buy"))}
          >
            {t("app.home.buy")}
          </button>

          <button
            className="fw-bold text-white rounded-5 p-0 border-0"
            style={{
              background: operationType === "Sell" ? "#D8AFF3" : "transparent",
              width: "48%",
            }}
            // onClick={() => dispatch(setOperationType("Sell"))}
          >
            {t("app.home.sell")}
          </button>
        </div>

        {operationType === "Buy" ? (
          <BuyBodyHome
            currencies={currencies}
            handleListCrypto={handleListCrypto}
          />
        ) : (
          <SellBodyHome
            currencies={currencies}
            handleListCrypto={handleListCrypto}
          />
        )}

        <Link
          to="/trade"
          className="d-block w-100 mt-3 mt-xl-5 text-decoration-none fw-bold text-white text-decoration-none py-2 text-center rounded-5"
          style={{ background: "#D8AFF3" }}
        >
          {t("app.home.exchange.button")}
        </Link>

        <p
          className="px-3 m-0 mt-2 mb-4"
          style={{
            fontSize: "7px",
            color: "#AB9F9F",
          }}
        >
          {t("app.home.exchange.purchase")}{" "}
        </p>
      </div>
    </div>
  );
};
