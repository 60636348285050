import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { IRootState } from "../../redux/store/store";
import transparentGlobe from "../../static/images/transparentGlobe.png"
import { useTranslation } from "react-i18next"

export const MeetCrypto = () => {
  const { t } = useTranslation();

  const [isVisible, setIsVisible] = useState(true);

  const { colorFirst, colorSecond } = useSelector(({ styleData }: IRootState) => styleData);

  const componentRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (componentRef.current) {
        const componentTopOffset =
          (window.innerHeight -
            //@ts-ignore
            componentRef.current.getBoundingClientRect().top) /
          window.innerHeight;

        const componentBottomOffset =
          (window.innerHeight -
            //@ts-ignore
            componentRef.current.getBoundingClientRect().bottom) /
          window.innerHeight;

        setIsVisible(componentBottomOffset < 0.6 && componentTopOffset > 0.3);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section
      className={`mx-auto w-100 mt-5 rounded-5 text-center position-relative section__size animated-component ${isVisible ? "visible" : "hidden"}`}
      style={{ maxWidth: "1320px", background: colorSecond }}
      ref={componentRef}
    >
      <div className="position-absolute w-100 mx-auto" style={{ top: "-70px" }}>
        <img
          src={transparentGlobe}
          alt="earth"
          style={{ width: "100%", maxWidth: "700px" }}
        />

        <p
          className="header__title text-center mx-auto"
          style={{
            width: "70%",
            lineHeight: ".8",
            fontFamily: "Wise Sans",
          }}
        >
          MEET CRYPTO WITHOUT LIMITS
        </p>

        <p
          className="w-50 text-center mx-auto fs-4 lh-1 my-5"
        >
          {t("personal.meetCrypto.sub")}
        </p>

        <Link
          to="/"
          className="navbarCont__button fw-bold text-dark text-decoration-none px-4 py-3 text-center rounded-5"
          style={{ backgroundColor: colorFirst }}
        >
          {t("personal.meetCrypto.button")}
        </Link>
      </div>
    </section>
  );
};
