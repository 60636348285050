import React from "react";
import cornerLogoBlack from "../static/icons/CornerSwapFinal.svg";

export const Page404 = () => (
  <div className="vh-100 vw-100 d-flex justify-content-center align-items-center">
    <div>
      <h1 className="text-center fw-bold mb-4">404</h1>

      <h2 className="text-center pb-5 mb-5">Something went wrong...</h2>

      <img
        src={cornerLogoBlack}
        alt="corner logo"
        className="d-block mx-auto"
        style={{ width: "200px" }}
      />
    </div>
  </div>
);
