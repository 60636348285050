import React, { useState, useEffect } from "react";
import SumsubWebSdk from "@sumsub/websdk-react";
import axios from "axios";

const backendUrl = process.env.REACT_APP_PUBLIC_API_BACKEND;

const KycSumsub = ({ token, stateStep }: any) => {
  const [accessToken, setAccessToken] = useState(null);

  useEffect(() => {
    GetToken();
  }, []);

  const GetToken = () => {
    axios
      .post(
        `${backendUrl}/kyc/sdkToken`,
        {},
        {
          headers: {
            authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setAccessToken(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const accessTokenExpirationHandler = () => {
    GetToken();
  };

  const onMessage = (message: string) => {
    if (message === "idCheckCompleted") {
      console.log("La vérification est terminée");
    }

    if (message === "idCheckCanceled") {
      console.log("La vérification a été annulée");
    }

    if (message === "idCheck.applicantReviewComplete") {
      setTimeout(() => {
        axios
          .post(
            `${backendUrl}/users/pd`,
            {},
            {
              headers: {
                authorization: `Bearer ${token}`,
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          )
          .then(function (response) {
            stateStep(1);
          })
          .catch(function (error) {
            if (error.code === "ERR_NETWORK") {
              console.log("ERR_NETWORK");
            }
          });
      }, 3000);
    }
  };

  const onError = (cb: any) => {
    console.log(cb);
  };

  return (
    <div className="container bg-light">
      {accessToken && (
        <SumsubWebSdk
          accessToken={accessToken}
          //@ts-ignore
          expirationHandler={accessTokenExpirationHandler}
          onMessage={onMessage}
          onError={onError}
        />
      )}
    </div>
  );
};

export default KycSumsub;
