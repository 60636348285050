import React, { useEffect, useState } from "react";
import laptopTop from "../../static/images/onRamp/laptopTop.png";
import laptopDown from "../../static/images/onRamp/laptopDown.png";
import { useSelector } from "react-redux";
import { IRootState } from "../../redux/store/store";
import { useTranslation } from "react-i18next";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

const signUpLink = process.env.REACT_APP_LINK_SIGNUP;

export const Header = () => {
  const { t } = useTranslation();

  const [moveAppImage, setMoveAppImage] = useState(false);
  const [moveTitle, setMoveTitle] = useState(false);
  const [moveSubTitle, setMoveSubTitle] = useState(false);
  const [isMobile] = useState(window.innerWidth < 991);
  const [laptopSize] = useState(window.innerWidth < 991 ? 100 : 120);

  const { color3 } = useSelector(({ styleData }: IRootState) => styleData);

  useEffect(() => {
    setMoveTitle(true);
    setMoveAppImage(true);
    setMoveSubTitle(true);
  }, []);

  return (
    <header className="onramp__header d-flex align-items-center">
      <div className="container mx-auto text-center text-lg-start d-flex flex-column flex-lg-row align-items-center align-items-lg-start">
        <div
          className="onramp__header-left d-flex flex-column"
          style={{
            flex: "1",
          }}
        >
          <p
            className="onramp__header-left--title lh-1"
            style={{
              transform: `translateY(${moveTitle ? 0 : 100}px)`,
              opacity: moveTitle ? 1 : 0,
              transition: "all 1s ease",
            }}
          >
            {t("onRamp.header.title.1")}
            <br />
            <span
              className="px-1"
              style={{
                background: color3,
                color: "white",
                lineHeight: "1.5",
              }}
            >
              {t("onRamp.header.title.2")}
            </span>{" "}
            <br />
            {t("onRamp.header.title.3")}
          </p>

          <p
            className="m-0 mt-4 text-start text-md-center text-lg-start"
            style={{
              fontSize: "18px",
              transform: `translateY(${moveSubTitle ? 0 : 100}px)`,
              opacity: moveSubTitle ? 1 : 0,
              transition: "all 1s ease",
            }}
          >
            {t("onRamp.header.sub")}
          </p>

          <div
            className="mt-5 d-flex flex-column flex-md-row justify-content-center justify-content-lg-start"
            style={{
              transform: `translateY(${moveSubTitle ? 0 : 100}px)`,
              opacity: moveSubTitle ? 1 : 0,
              transition: "all 1s ease",
            }}
          >
            <Link
              to="/contactus"
              className="text-white rounded text-decoration-none"
              style={{ padding: "12px 40px", background: color3 }}
            >
              {t("onRamp.header.button.1")}
            </Link>

            <Link
              to="/signup"
              // to={signUpLink ? signUpLink : "/"}
              className="rounded text-dark text-decoration-none bg-white border ms-md-4 mt-3 mt-md-0"
              style={{ padding: "12px 40px" }}
            >
              {t("onRamp.header.button.2")}
            </Link>
          </div>
        </div>

        <div
          className="onramp__header-right mt-lg-5 mt-xl-0"
          style={{
            transform: `scale(${moveAppImage ? "1, 1" : "0.9, 0.9"})`,
            marginBottom: `${isMobile ? 0.35 * window.innerHeight : 0}px`,
            flex: 1,
            flexGrow: 1,
          }}
        >
          <div
            className="position-absolute "
            style={{
              transformOrigin: "50% 100%",
              transform: `rotateX(${moveAppImage ? 0 : -83}deg)`,
              transition: "all 2.5s cubic-bezier(0.215, 0.61, 0.355, 1)",
            }}
          >
            <img
              src={laptopTop}
              style={{
                width: `${laptopSize}%`,
                height: `${laptopSize}%`,
              }}
            />
          </div>

          <div
            className="position-absolute"
            style={{
              width: "100%",
              transition: "all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1)",
            }}
          >
            <img
              src={laptopDown}
              alt="hponeAppImage"
              style={{
                width: `${laptopSize}%`,
                height: `${laptopSize}%`,
              }}
            />
          </div>
        </div>
      </div>
    </header>
  );
};
