import React, { useState } from "react";
import { NewPasswordStep1 } from "../components/NewPassword/NewPasswordStep1"
import { NewPasswordStep2 } from "../components/NewPassword/NewPasswordStep2"

export const NewPassword = () => {
  const [stepPassword, setStepPassword] = useState(1)

  return (
    <div
      className="d-flex flex-column h-100 w-100 m-0 p-0 position-relative"
      style={{ minHeight: "100vh", minWidth: "100vw", background: "#252430" }}
    >
      {stepPassword === 1 && <NewPasswordStep1 setStepPassword={setStepPassword} />}

      {stepPassword === 2 && <NewPasswordStep2 />}
    </div>
  );
};
