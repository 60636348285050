import React from "react";
import { Layout } from "../components/Layout";
import { Link } from "react-router-dom";
import logoCorner from "../static/icons/CornerSwapFinal.svg";

export const AmlKyc = () => {
  return (
    <Layout>
      <div className="container py-5">
        <div className="w-100 py-4">
          <Link to="/">
            <img src={logoCorner} alt="logoCorner" height={70} />
          </Link>
        </div>

        <div className="d-flex justify-content-center flex-wrap px-2">
          <Link
            to="/policy"
            className="d-flex privacy__button px-3 px-lg-4 py-1 py-lg-3 rounded-5 text-decoration-none fw-bold me-2 me-sm-3 mt-2"
            style={{
              color: "#B1B1B1",
              border: "1px solid #B1B1B1",
            }}
          >
            Privacy Policy
          </Link>

          <Link
            to="/security"
            className="d-flex privacy__button px-3 px-lg-4 py-1 py-lg-3 rounded-5 text-decoration-none me-2 me-sm-3 mt-2"
            style={{
              color: "#B1B1B1",
              border: "1px solid #B1B1B1",
            }}
          >
            Security
          </Link>

          <Link
            to="/amlkyc"
            className="d-flex privacy__button px-3 px-lg-4 py-1 py-lg-3 rounded-5 text-decoration-none me-2 me-sm-3 mt-2 border border-dark text-dark"
          >
            AML/KYC
          </Link>

          <Link
            to="/conditions"
            className="d-flex privacy__button px-3 px-lg-4 py-1 py-lg-3 rounded-5 text-decoration-none mt-2"
            style={{
              color: "#B1B1B1",
              border: "1px solid #B1B1B1",
            }}
          >
            Terms and conditions
          </Link>
        </div>
        <p className="privacy__title mt-5">
          Anti-Money Laundering (AML) and Know Your Customer (KYC) Policy
        </p>
        <p className="privacy__text">Last Updated: 06.06.24</p>
        <p className="privacy__text">
          Cornerswap ("we," "our," or "us") is committed to complying with all
          applicable anti-money laundering (AML) and know your customer (KYC)
          regulations to prevent and detect money laundering, terrorist
          financing, and other illicit activities. This AML/KYC Policy outlines
          the procedures we follow to ensure compliance with these regulations.
          By using Cornerswap, you agree to comply with this policy.
        </p>
        <p className="privacy__subtitle">1. AML/KYC Compliance Officer</p>
        <p className="privacy__text">
          We have designated an AML/KYC Compliance Officer responsible for
          overseeing our compliance with AML and KYC regulations. The Compliance
          Officer's duties include:
        </p>
        <ul className="privacy__text">
          <li>Developing and implementing AML/KYC policies and procedures</li>

          <li>Conducting ongoing AML/KYC training for employees.</li>

          <li>Monitoring transactions for suspicious activity.</li>

          <li>eporting suspicious activities to relevant authorities.</li>

          <li>Ensuring record-keeping and reporting requirements are met.</li>
        </ul>
        <p className="privacy__subtitle">
          2. Customer Identification Program (CIP)
        </p>
        <p className="privacy__text">
          Our Customer Identification Program (CIP) is designed to verify the
          identity of our customers and ensure we have sufficient information to
          assess the risk of illicit activity. The CIP includes the following
          procedures:
        </p>
        <ul className="privacy__text">
          <li>
            <span className="fw-bold">Account Registration: </span>
            Customers must provide accurate and complete personal information
            during account registration, including name, date of birth, address,
            email address, and phone number.
          </li>

          <li>
            <span className="fw-bold">Identity Verification: </span>
            We verify the identity of customers using reliable, independent
            sources such as government-issued identification documents (e.g.,
            passport, driver’s license) and utility bills.
          </li>

          <li>
            <span className="fw-bold">Enhanced Due Diligence (EDD): </span>
            For high-risk customers, we perform enhanced due diligence, which
            may include additional verification steps, such as obtaining
            information about the source of funds and the nature of the
            customer's business.
          </li>
        </ul>
        <p className="privacy__subtitle">3. Ongoing Monitoring</p>
        <p className="privacy__text">
          We continuously monitor customer transactions and activities to detect
          and prevent suspicious behavior. This includes:
        </p>
        <ul className="privacy__text">
          <li>
            <span className="fw-bold">Transaction Monitoring: </span>
            We use automated systems to monitor transactions for unusual or
            suspicious activity, such as large or irregular transactions,
            transactions involving high-risk jurisdictions, and rapid movement
            of funds.
          </li>

          <li>
            <span className="fw-bold">Periodic Reviews: </span>
            We conduct periodic reviews of customer accounts to ensure the
            information remains accurate and up-to-date, and to reassess the
            customer's risk profile.
          </li>
        </ul>
        <p className="privacy__subtitle">
          4. Suspicious Activity Reporting (SAR)
        </p>
        <p className="privacy__text">
          If we detect suspicious activity that may indicate money laundering or
          other illegal activities, we take the following steps:
        </p>
        <ul className="privacy__text">
          <li>
            <span className="fw-bold">Investigation: </span>
            Our AML/KYC Compliance Officer investigates the suspicious activity
            to determine whether it is indeed suspicious and requires reporting.
          </li>

          <li>
            <span className="fw-bold">Reporting: </span>
            If suspicious activity is confirmed, we file a Suspicious Activity
            Report (SAR) with the relevant authorities in accordance with
            applicable laws and regulations.
          </li>

          <li>
            <span className="fw-bold">Cooperation with Authorities: </span>
            We cooperate fully with law enforcement and regulatory authorities
            in their investigations and provide any necessary information and
            documentation.
          </li>
        </ul>
        <p className="privacy__subtitle">5. Record Keeping</p>
        <p className="privacy__text">
          We maintain comprehensive records related to our AML/KYC procedures,
          including:
        </p>
        <ul className="privacy__text">
          <li>
            <span className="fw-bold">Customer Identification Records: </span>
            Records of the information and documents used to verify customer
            identities are kept for a minimum of five years after the end of the
            customer relationship.
          </li>

          <li>
            <span className="fw-bold">Transaction Records: </span>
            Records of all transactions, including amounts, dates, and
            counterparties, are maintained for a minimum of five years.
          </li>

          <li>
            <span className="fw-bold">Suspicious Activity Reports: </span>
            Copies of all SARs and related documentation are kept for a minimum
            of five years.
          </li>
        </ul>
        <p className="privacy__subtitle">6. Employee Training and Awareness</p>
        <p className="privacy__text">
          We provide regular training for all employees on AML/KYC regulations,
          policies, and procedures. Training covers:
        </p>
        <ul className="privacy__text">
          <li>
            <span className="fw-bold">Recognizing Suspicious Activity: </span>
            How to identify signs of money laundering and other suspicious
            behavior.
          </li>

          <li>
            <span className="fw-bold">AML/KYC Procedures: </span>
            The steps to take when verifying customer identities and monitoring
            transactions.
          </li>

          <li>
            <span className="fw-bold">Reporting Requirements: </span>
            How to report suspicious activities internally and to relevant
            authorities.
          </li>
        </ul>
        <p className="privacy__subtitle">7. Risk Assessment</p>
        <p className="privacy__text">
          We conduct regular risk assessments to identify and mitigate risks
          related to money laundering and terrorist financing. This includes
          evaluating:
        </p>
        <ul className="privacy__text">
          <li>
            <span className="fw-bold">Customer Risk: </span>
            Assessing the risk associated with different types of customers,
            their activities, and their geographic locations.
          </li>

          <li>
            <span className="fw-bold">Product and Service Risk: </span>
            Evaluating the risk associated with the products and services we
            offer, and implementing appropriate controls to mitigate these
            risks.
          </li>

          <li>
            <span className="fw-bold">Geographic Risk: </span>
            Considering the risks associated with doing business in different
            jurisdictions and regions, particularly high-risk countries.
          </li>
        </ul>
        <p className="privacy__subtitle">8. Policy Review</p>
        <p className="privacy__text">
          This AML/KYC Policy is reviewed and updated regularly to ensure it
          remains effective and compliant with current regulations and industry
          best practices.
        </p>
        <p className="privacy__subtitle">Contact Us</p>
        <p className="privacy__text">
          If you have any questions or concerns about this AML/KYC Policy,
          please contact us at:{" "}
          <span className="fw-bold">office@cornerswap.io</span>
        </p>
        <p className="privacy__text">
          By using our platform, you acknowledge that you have read and
          understood this AML/KYC Policy. Thank you for helping us maintain the
          security and integrity of Cornerswap.
        </p>
      </div>
    </Layout>
  );
};
