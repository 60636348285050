import React, { useEffect } from "react";
import { Layout } from "../components/Layout";
import { Link } from "react-router-dom";
import logoCorner from "../static/icons/CornerSwapFinal.svg";

export const TermsConditions = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <Layout>
      <div className="container py-5">
        <div className="w-100 py-4">
          <Link to="/">
            <img src={logoCorner} alt="logoCorner" height={70} />
          </Link>
        </div>

        <div className="d-flex justify-content-center flex-wrap px-2">
          <Link
            to="/policy"
            className="d-flex privacy__button px-3 px-lg-4 py-1 py-lg-3 rounded-5 text-decoration-none fw-bold me-2 me-sm-3 mt-2"
            style={{
              color: "#B1B1B1",
              border: "1px solid #B1B1B1",
            }}
          >
            Privacy Policy
          </Link>

          <Link
            to="/security"
            className="d-flex privacy__button px-3 px-lg-4 py-1 py-lg-3 rounded-5 text-decoration-none me-2 me-sm-3 mt-2"
            style={{
              color: "#B1B1B1",
              border: "1px solid #B1B1B1",
            }}
          >
            Security
          </Link>

          <Link
            to="/amlkyc"
            className="d-flex privacy__button px-3 px-lg-4 py-1 py-lg-3 rounded-5 text-decoration-none me-2 me-sm-3 mt-2"
            style={{
              color: "#B1B1B1",
              border: "1px solid #B1B1B1",
            }}
          >
            AML/KYC
          </Link>

          <Link
            to="/conditions"
            className="d-flex privacy__button px-3 px-lg-4 py-1 py-lg-3 rounded-5 text-decoration-none mt-2 border border-dark text-dark"
            style={{
              color: "#B1B1B1",
              border: "1px solid #B1B1B1",
            }}
          >
            Terms and conditions
          </Link>
        </div>
        <p className="privacy__title mt-5">Terms and Conditions</p>
        <p className="privacy__text">Last Updated: 01.10.24</p>
        <p className="privacy__subtitle mt-5">1. Acceptance of Terms</p>
        <p className="privacy__text">
          Welcome to Cornerswap! These Terms and Conditions (thereby referred to
          as "Terms") govern your access to and use of our cryptocurrency
          trading platform registered under the domain: www.cornerswap.io (the
          "Service").
        </p>
        <p className="privacy__text">
          To the extent that there is a conflict between these Terms and any
          applicable additional terms, these Terms will control unless expressly
          stated otherwise. If you don't agree with these Terms, you may not use
          the Services and should not visit the Site or otherwise engage with
          the Services.
        </p>
        <p className="privacy__text">
          By using the Service, you affirm that you are at least 18 years old
          and have the legal capacity to enter into these Terms. If you are
          using the Service on behalf of an organization, you represent and
          warrant that you have the authority to bind that organization to these
          Terms.
        </p>

        <p className="privacy__subtitle mt-5">2. Account Registration</p>
        <p className="privacy__text">
          To use certain features of the Service, you must create an account.
          When you register for an account, you agree to:
        </p>
        <ul className="privacy__text">
          <li>Provide accurate, current, and complete information.</li>

          <li>
            Maintain and promptly update your information to keep it accurate,
            current, and complete.
          </li>

          <li>Keep your account credentials secure and confidential.</li>

          <li>
            Notify us immediately of any unauthorized use of your account or any
            other security breach.
          </li>

          <li>
            Be responsible for all activities that occur under your account.
          </li>
        </ul>
        <p className="privacy__subtitle mt-5">3. Use of the Service</p>
        <p className="privacy__text">
          You may only use the Services if you comply with this Agreement
          (including, without limitation, these Terms), applicable third-party
          policies, and all applicable laws, rules, regulations, and related
          guidance. The following conduct is prohibited:
        </p>
        <ul className="privacy__text">
          <li>
            Using the Services for, or to promote or facilitate, illegal
            activity (including, without limitation, money laundering, financing
            terrorism, tax evasion, buying or selling illegal drugs, contraband,
            counterfeit goods, or illegal weapons).
          </li>

          <li>
            Exploiting the Services for any unauthorized commercial purpose.
          </li>

          <li>
            Uploading or transmitting viruses, worms, Trojan horses, time bombs,
            cancel bots, spiders, malware, or any other type of malicious code
            that will or may be used in any way that will affect the
            functionality or operation of the Services.
          </li>

          <li>
            Attempting to or actually copying or making unauthorized use of all
            or any portion of the Services, including by attempting to reverse
            compile, reformatting or framing, disassemble, or reverse engineer
            any part of the Services.
          </li>

          <li>
            Harvesting or otherwise collecting information from the Services for
            any unauthorized purpose.
          </li>

          <li>
            Using the Services under false or fraudulent pretenses or otherwise
            being deceitful.
          </li>

          <li>
            Interfering with other users’ access to or use of the Services.
          </li>

          <li>
            Interfering with or circumventing the security features of the
            Services or any third party’s systems, networks, or resources used
            in the provision of Services.
          </li>

          <li>
            Engaging in any attack, hack, denial-of-service attack,
            interference, or exploit of any smart contract in connection with
            the use of the Service (and operations performed by a user that are
            technically permitted by a smart contract may nevertheless be a
            violation of our Agreement, including these Terms, and the law)
          </li>

          <li>Engaging in any anticompetitive behavior or other misconduct.</li>
        </ul>
        <p className="privacy__text">
          Violating our rules may result in our intervention.
        </p>
        <p className="privacy__text">
          You agree and acknowledge that if you use the Services to engage in
          conduct prohibited by applicable law, we permanently reserve the right
          to completely or partially restrict or revoke your access to the
          Services, either completely or for some time, at our sole discretion.
          We reserve the right to amend, rectify, edit, or otherwise alter
          transaction data to remediate or mitigate any damage caused either to
          us or to any other person as a result of a user’s violation of this
          Agreement or applicable law.
        </p>
        <p className="privacy__text">
          We reserve the right to investigate violations.
        </p>
        <p className="privacy__text">
          We reserve the right to investigate and prosecute any suspected
          breaches of this Agreement, including the Terms. We may disclose any
          information as necessary to satisfy any law, regulation, legal
          process, or governmental request.
        </p>
        <p className="privacy__subtitle mt-5">4. Cryptocurrency Transactions</p>
        <ul className="privacy__text">
          <li>
            <span className="fw-bold">Transactions: </span>
            ou are solely responsible for any cryptocurrency transactions you
            initiate or receive through the Service. We do not guarantee the
            value, transferability, or security of any cryptocurrency.
          </li>

          <li>
            <span className="fw-bold">Fees: </span>
            We may charge fees for certain transactions or services. All fees
            will be disclosed to you before the completion of the transaction.
          </li>

          <li>
            <span className="fw-bold">Taxes: </span>
            You are responsible for determining and fulfilling your tax
            obligations related to your use of the Service.
          </li>
        </ul>
        <p className="privacy__subtitle mt-5">5. Intellectual Property</p>
        <p className="privacy__text">
          All content and materials available on the Service, including text,
          graphics, logos, and software, are the property of Cornerswap or its
          licensors and are protected by intellectual property laws. You may not
          use, reproduce, modify, or distribute any content from the Service
          without our prior written consent.
        </p>
        <p className="privacy__subtitle mt-5">6. Privacy</p>
        <p className="privacy__text">
          Your use of the Service is subject to our Privacy Policy, which
          explains how we collect, use, and protect your personal information.
          By using the Service, you consent to our collection and use of your
          information as described in the Privacy Policy.
        </p>
        <p className="privacy__subtitle mt-5">7. Security</p>
        <p className="privacy__text">
          We take reasonable measures to protect the security of your
          information and transactions. However, we cannot guarantee that
          unauthorized third parties will never be able to defeat our security
          measures or misuse your information. You acknowledge that you provide
          your information at your own risk.
        </p>
        <p className="privacy__subtitle mt-5">8. Termination</p>
        <p className="privacy__text">
          This Agreement is effective unless and until terminated by either you
          or us. You may terminate your Agreement with us at any time by ceasing
          all access to the Services. If, in our sole judgment, you fail, or we
          suspect that you have failed, to comply with any term or provision of
          the Agreement (including without limitation any provision of these
          Terms), we reserve the right to terminate our Agreement with you and
          deny you access to the Services. We further reserve the right to
          restrict your access to the Services or to stop providing you with all
          or a part of the Services at any time and for no reason, including,
          without limitation, if we reasonably believe: (a) your use of the
          Services exposes us to risk or liability; (b) you are using the
          Services for unlawful purposes; or (c) it is not commercially viable
          to continue providing you with our Services. All of these are in
          addition to any other rights and remedies that may be available to us,
          whether in equity or at law, all of which we expressly reserve.
        </p>
        <p className="privacy__subtitle mt-5">
          9. Disclaimers and Limitation of Liability
        </p>
        <p className="privacy__text">
          We make no representations or warranties.
        </p>
        <p className="privacy__text">
          The services are provided on an “as is” and “as available” basis. we
          make no guarantees of any kind in connection with the services. To the
          maximum extent permitted under applicable law, the indemnified parties
          disclaim all warranties and conditions, whether express or implied, of
          merchantability, fitness for a particular purpose, or non-infringement
          and disclaim all responsibility and liability for:
        </p>
        <ul className="privacy__text">
          <li>
            The services being accurate, complete, current, reliable,
            uninterrupted, timely, secure, or error-free. information
            (including, without limitation, the value or outcome of any
            transaction) available through the service is provided for general
            information only and should not be relied upon or used as the sole
            basis for making decisions. any reliance on the services is at your
            own risk.
          </li>

          <li>
            Injury or damage resulting from the services. for example, you
            expressly acknowledge, understand, and agree that the services may
            contain audio-visual effects, strobe lights, or other materials that
            may affect your physical senses and/or physical condition.
            Furthermore, you expressly acknowledge that the Cornerswap LLC
            indemnified parties are not responsible for loss or damage caused by
            another user’s conduct, unauthorized actors, or any unauthorized
            access to or use of the services.
          </li>

          <li>
            Viruses, worms, trojan horses, time bombs, cancel bots, spiders,
            malware, or other type of malicious code may be used in any way to
            affect the functionality or operation of the services.
          </li>
        </ul>
        <p className="privacy__subtitle mt-5">10. Limitation of Liability.</p>
        <p className="privacy__text">
          To the maximum extent permitted by law, in no event shall any
          Cornerswap LLC indemnified party be liable to you for any loss,
          damage, or injury of any kind including any direct, indirect, special,
          incidental, exemplary, consequential, or punitive losses or damages,
          or damages for system failure or malfunction or loss of profits, data,
          use, business or good-will or other intangible losses, arising out of
          or in connection with:
        </p>
        <ul className="privacy__text">
          <li>The services or your inability to use or access the services.</li>

          <li>
            Misuse of the services (including without limitation, unauthorized
            access of the services)
          </li>

          <li>Any user conduct on the services.</li>
          <li>
            Termination, suspension or restriction of access to any the
            services.
          </li>
        </ul>
        <p className="privacy__text">
          In addition to the foregoing, no Cornerswap LLC indemnified party
          shall be liable for any damages caused in whole or in part by:
        </p>
        <ul className="privacy__text">
          <li>
            User error, such as forgotten passwords incorrectly constructed
            smart contracts, or other transactions.
          </li>

          <li>Server failure or data loss.</li>

          <li>
            The malfunction, unexpected function, or unintended function of the
            blockchain, any computer or crypto asset network (including any
            wallet provider), including without limitation losses associated
            with network forks, replay attacks, double-spend attacks, sybil
            attacks, 51% attacks, governance disputes, mining difficulty,
            changes in cryptography or consensus rules, hacking, or
            cybersecurity breaches.
          </li>
          <li>Any change in the value of any cryptoasset.</li>
          <li>Any change in law, regulation, or policy.</li>
          <li>Events of force majeure.</li>
          <li>Any third party.</li>
        </ul>
        <p className="privacy__text">
          This limitation of liability is intended to apply without regard to
          whether other provisions of these terms have been breached or have
          proven ineffective. the limitations outlined in this section shall
          apply regardless of the form of action, whether the asserted liability
          or damages are based on contract, indemnification, tort, strict
          liability, statute, or any other legal or equitable theory, and
          whether or not the Cornerswap LLC indemnified parties have been
          informed of the possibility of any such damage.
        </p>
        <p className="privacy__text">
          Under no circumstances shall any Cornerswap LLC indemnified party be
          required to deliver to you any virtual currency as damages, make
          specific performance, or any other remedy.
        </p>
        <p className="privacy__text">
          Some jurisdictions do not allow the exclusion or limitation of certain
          warranties and liabilities provided in this section; accordingly, some
          of the above limitations and disclaimers may not apply to you. To the
          extent applicable law does not permit Cornerswap LLC Indemnified
          Parties to disclaim certain warranties or limit certain liabilities,
          the extent of Cornerswap LLC Indemnified Parties’ liability and the
          scope of any such warranties will be as permitted under applicable
          law.
        </p>

        <p className="privacy__subtitle mt-5">11. Indemnification</p>
        <p className="privacy__text">
          You agree to indemnify, defend, and hold harmless the Cornerswap LLC
          Indemnified Parties from any claim or demand, including reasonable
          attorneys’ fees, made by any third party due to or arising out of:
        </p>
        <ul className="privacy__text">
          <li>
            Your breach or alleged breach of the Agreement (including, without
            limitation, these Terms).
          </li>
          <li>Anything you contribute to the Services.</li>
          <li>
            Your misuse of the Services, or any smart contract and/or script
            related thereto.
          </li>
          <li>
            Your violation of any laws, rules, regulations, codes, statutes,
            ordinances, or orders of any governmental or quasi-governmental
            authorities.
          </li>
          <li>
            Your violation of the rights of any third party, including any
            intellectual property right, publicity, confidentiality, property,
            or privacy right.
          </li>
          <li>Your use of a third-party product, service, and/or website.</li>
          <li>Any misrepresentation made by you.</li>
        </ul>
        <p className="privacy__text">
          We reserve the right to assume, at your expense, the exclusive defense
          and control of any matter subject to indemnification by you. You agree
          to cooperate with our defense of any claim. You will not in any event
          settle any claim without our prior written consent.
        </p>
        <p className="privacy__text">
          We reserve the right to assume, at your expense, the exclusive defense
          and control of any matter subject to indemnification by you. You agree
          to cooperate with our defense of any claim. You will not in any event
          settle any claim without our prior written consent.
        </p>
        <p className="privacy__subtitle mt-5">
          12. Governing Law and Dispute Resolution
        </p>
        <p className="privacy__text">
          These Terms shall be governed by and construed under the Polish laws,
          without regard to its conflict of laws principles. Any disputes
          arising out of or relating to these Terms or the Service shall be
          resolved through binding arbitration in accordance with Polish law,
          except that either party may seek injunctive or other equitable relief
          in a court of competent jurisdiction.
        </p>
        <p className="privacy__subtitle mt-5">13. Changes to the Terms</p>
        <p className="privacy__text">
          We may update these Terms from time to time. If we make any material
          changes, we will notify you by updating the date at the top of these
          Terms and, in some cases, providing you with additional notice (such
          as adding a statement to our homepage or sending you a notification).
          We encourage you to review these Terms periodically to stay informed
          about our terms and conditions.
        </p>

        <p className="privacy__subtitle mt-5">14. Contact Us</p>
        <p className="privacy__text">
          If you have any questions or concerns about these Terms, please
          contact us at <span className="fw-bold">support@cornerswap.com</span>
        </p>
        <p className="privacy__text">
          By using our Service, you acknowledge that you have read and
          understood these Terms and agree to be bound by them. Thank you for
          using Cornerswap.
        </p>
      </div>
    </Layout>
  );
};
