import React, { useState } from "react";
import { Layout } from "../components/Layout";
import { Link } from "react-router-dom";
import { ContactForm } from "../components/Application/ContactForm";
import plusIcon from "../static/icons/faq/plusIcon.png";

export const FAQ = () => {
  const [question1, setQuestion1] = useState(false);
  const [question2, setQuestion2] = useState(false);
  const [question3, setQuestion3] = useState(false);
  const [question4, setQuestion4] = useState(false);
  const [question5, setQuestion5] = useState(false);

  return (
    <Layout>
      <div className="container py-5 my-md-5">
        <p className="contact__title">FAQ</p>

        <div className="w-100 border-bottom border-dark mb-5"></div>

        <div className="d-flex">
          <span className="contact__subtitle">1.</span>

          <div className="ms-2 me-auto">
            <span className="contact__subtitle">
              What are the differences between Cornerswap and a traditional
              bank?
            </span>

            <p
              className="contact__text col-md-10 col-lg-11 col-xxl-10"
              style={{
                maxHeight: question1 ? "100px" : "0",
                opacity: question1 ? 1 : 0,
                transition: "all 1s ease-in-out",
                overflow: "hidden",
              }}
            >
              A CornerSwap account enables users to manage and transact with
              crypto assets like Bitcoin and Ethereum. Our platform offers
              comprehensive services including crypto banking, trading, and
              international payments, all in one place.
            </p>
          </div>

          <button
            className="d-flex align-items-center bg-transparent border-0 ms-3 mt-1 mt-sm-2 mt-md-3"
            style={{
              height: "18px",
            }}
            onClick={() => setQuestion1(!question1)}
          >
            <img
              src={plusIcon}
              alt="plusIcon"
              style={{
                height: "100%",
                transform: question1 ? "rotate(45deg)" : "rotate(0deg)",
                transition: "all .6s ease-in-out",
              }}
            />
          </button>
        </div>

        <div className="d-flex">
          <span className="contact__subtitle">2.</span>

          <div className="ms-2 me-auto">
            <span className="contact__subtitle">
              What are the supported FIAT currencies to buy crypto?
            </span>

            <p
              className="contact__text col-md-10 col-lg-11 col-xxl-10"
              style={{
                maxHeight: question2 ? "100px" : "0",
                opacity: question2 ? 1 : 0,
                transition: "all 1s ease-in-out",
                overflow: "hidden",
              }}
            >
              At the moment we accept payments in USD, EUR, CHF, GBP, JPY, AUD,
              PLN and CAD. However, we plan to offer even wider variety of
              currencies
            </p>
          </div>

          <button
            className="d-flex align-items-center bg-transparent border-0 ms-3 mt-1 mt-sm-2 mt-md-3"
            style={{
              height: "18px",
            }}
            onClick={() => setQuestion2(!question2)}
          >
            <img
              src={plusIcon}
              alt="plusIcon"
              style={{
                height: "100%",
                transform: question2 ? "rotate(45deg)" : "rotate(0deg)",
                transition: "all .6s ease-in-out",
              }}
            />
          </button>
        </div>

        <div className="d-flex">
          <span className="contact__subtitle">3.</span>

          <div className="ms-2 me-auto">
            <span className="contact__subtitle">
              Is using Cornerswap free? Are there any fees for making
              transactions?
            </span>

            <p
              className="contact__text col-md-10 col-lg-11 col-xxl-10"
              style={{
                maxHeight: question3 ? "100px" : "0",
                opacity: question3 ? 1 : 0,
                transition: "all 1s ease-in-out",
                overflow: "hidden",
              }}
            >
              Yes, a Cornerswap account i totally free and does not require any
              sort of payment. When you buy crypto using with fiat, we charge
              you with a currency exchange fee and a processing fee. To learn
              more about fee rates, go to our{" "}
              <Link to="/conditions" className="text-dark">
                Terms and conditions
              </Link>
              .
            </p>
          </div>

          <button
            className="d-flex align-items-center bg-transparent border-0 ms-3 mt-1 mt-sm-2 mt-md-3"
            style={{
              height: "18px",
            }}
            onClick={() => setQuestion3(!question3)}
          >
            <img
              src={plusIcon}
              alt="plusIcon"
              style={{
                height: "100%",
                transform: question3 ? "rotate(45deg)" : "rotate(0deg)",
                transition: "all .6s ease-in-out",
              }}
            />
          </button>
        </div>

        <div className="d-flex">
          <span className="contact__subtitle">4.</span>

          <div className="ms-2 me-auto">
            <span className="contact__subtitle">
              What crypto can I trade on your platform?
            </span>

            <p
              className="contact__text col-md-10 col-lg-11 col-xxl-10"
              style={{
                maxHeight: question4 ? "100px" : "0",
                opacity: question4 ? 1 : 0,
                transition: "all 1s ease-in-out",
                overflow: "hidden",
              }}
            >
              We do our best to constantly grow the number of offered trading
              pairs and add newest and hottest tokens for you. Currently we
              offer trading of BTC, ETH, SOL, USDT, USDC, MATIC, SHIB, DOGE,
              BNB, XRP, ADA, SUSHI and AVAX.
            </p>
          </div>

          <button
            className="d-flex align-items-center bg-transparent border-0 ms-3 mt-1 mt-sm-2 mt-md-3"
            style={{
              height: "18px",
            }}
            onClick={() => setQuestion4(!question4)}
          >
            <img
              src={plusIcon}
              alt="plusIcon"
              style={{
                height: "100%",
                transform: question4 ? "rotate(45deg)" : "rotate(0deg)",
                transition: "all .6s ease-in-out",
              }}
            />
          </button>
        </div>

        <div className="d-flex">
          <span className="contact__subtitle">5.</span>

          <div className="ms-2 me-auto">
            <span className="contact__subtitle">
              How do I start trading on Cornerswap?
            </span>

            <p
              className="contact__text col-md-10 col-lg-11 col-xxl-10"
              style={{
                maxHeight: question5 ? "100px" : "0",
                opacity: question5 ? 1 : 0,
                transition: "all 1s ease-in-out",
                overflow: "hidden",
              }}
            >
              In order to buy or trade cryptocurrencies, you must connect your
              crypto wallet to our platform. You will find a full list of
              supported wallets in our{" "}
              <Link to="/conditions" className="text-dark">
                Terms and conditions
              </Link>
              .
            </p>
          </div>

          <button
            className="d-flex align-items-center bg-transparent border-0 ms-3 mt-1 mt-sm-2 mt-md-3"
            style={{
              height: "18px",
            }}
            onClick={() => setQuestion5(!question5)}
          >
            <img
              src={plusIcon}
              alt="plusIcon"
              style={{
                height: "100%",
                transform: question5 ? "rotate(45deg)" : "rotate(0deg)",
                transition: "all .6s ease-in-out",
              }}
            />
          </button>
        </div>

        <div className="w-100 border-bottom border-dark mt-5"></div>

        <p className="faq__message text-center mt-4">
          Didn’t find the right answer? Send us a message.
        </p>

        <div className="text-center mt-4">
          <Link
            to="/contactus"
            className="btn text-white fw-bols fs-3 rounded-5 px-4 py-1"
            style={{
              backgroundColor: "#DBB1F6",
            }}
          >
            Contact us
          </Link>
        </div>
      </div>
    </Layout>
  );
};
