import React, { useEffect, useState } from "react";
import LayoutApp from "../../components/Application/LayoutApp";
import { useDispatch, useSelector } from "react-redux";
import i18next from "i18next";
import { setLangIcon, setLangValue } from "../../redux/slices/language.slice";
import i18n from "../../i18n/config";
import { IRootState } from "../../redux/store/store";
import * as yup from "yup";
import { Field, Formik, Form } from "formik";
import selectArrow from "../../static/icons/app/Profile/selectArrow.svg";
import { currencies } from "../../components/Application/Trade/ExchangeTrade";
import { setInputCurrencyBuy } from "../../redux/slices/order.slice";
import { setCountry } from "../../redux/slices/auth.slice"
import { useTranslation } from "react-i18next"

export const Profile = () => {
  const { t } = useTranslation();

  const { lngValue } = useSelector(
    ({ languageData }: IRootState) => languageData
  );
  const { inputCurrencyBuy } = useSelector(
    ({ orderData }: IRootState) => orderData
  );
  const userCountry = useSelector(
    ({ authData }: IRootState) => authData.fetchAuth.country
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const currency = localStorage.getItem("currency");

    if (currency) {
      const newCurrency = currencies.find((cur) => cur.maj === currency);

      newCurrency && dispatch(setInputCurrencyBuy(newCurrency));
    }
  }, []);

  const handleLanguage = (e: React.ChangeEvent<HTMLSelectElement>) => {
    i18n.changeLanguage(e.target.value);

    dispatch(setLangValue(e.target.value));

    localStorage.setItem("lng", e.target.value);

    switch (e.target.value) {
      case "en":
        localStorage.setItem(
          "lngIcon",
          "https://www.shutterstock.com/image-vector/british-flag-standard-colors-circular-600nw-2204124575.jpg"
        );
        dispatch(
          setLangIcon(
            "https://www.shutterstock.com/image-vector/british-flag-standard-colors-circular-600nw-2204124575.jpg"
          )
        );
        break;
      case "fr":
        localStorage.setItem(
          "lngIcon",
          "https://cdn.countryflags.com/thumbs/france/flag-3d-round-250.png"
        );
        dispatch(
          setLangIcon(
            "https://cdn.countryflags.com/thumbs/france/flag-3d-round-250.png"
          )
        );
        break;
      default:
        break;
    }
  };

  const handleCurrency = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newCurrency = currencies.find(
      (currency) => currency.maj === e.target.value
    );

    newCurrency && dispatch(setInputCurrencyBuy(newCurrency));

    localStorage.setItem("currency", e.target.value);
  };

  return (
    <LayoutApp>
      <div
        className="app__card rounded-4 px-4 pb-5 py-2 pt-xl-4 mb-3"
      >
        <p className="app__payment-step3--title mt-5">{t("app.profile.title")}</p>

        <div
          className="mb-4"
          style={{
            border: "1px solid #D4D4D4",
          }}
        />

        <div className="row p-0 m-0">
          <div className="col-12 col-lg-6 p-0">
            <p className="mb-1 app__payment-step3--subtitle">{t("app.profile.language")}</p>

            <select
              name="topic"
              value={lngValue}
              onChange={handleLanguage}
              className={`w-100 app__payment-step3--subtitle fw-semibold bg-transparent color-text-dark border-color1 px-3 py-3 mt-3 `}
              style={{
                borderRadius: "11px",
                outline: "none",
                appearance: "none",
                backgroundImage: `url(${selectArrow})`,
                backgroundPosition: "right 10px center",
                backgroundRepeat: "no-repeat",
              }}
            >
              <option value="en"> English</option>
              <option value="fr"> French</option>
            </select>

            <p className="mb-1 app__payment-step3--subtitle mt-4">{t("app.profile.currency")}</p>

            <select
              name="topic"
              value={inputCurrencyBuy.maj}
              onChange={handleCurrency}
              className={`w-100 app__payment-step3--subtitle fw-semibold bg-transparent color-text-dark border-color1 px-3 py-3 mt-3 `}
              style={{
                borderRadius: "11px",
                outline: "none",
                appearance: "none",
                backgroundImage: `url(${selectArrow})`,
                backgroundPosition: "right 10px center",
                backgroundRepeat: "no-repeat",
              }}
            >
              {currencies.map((currency, index) => (
                <option key={index} value={currency.maj}>
                  {currency.name}
                </option>
              ))}
            </select>

            <p className="mb-1 app__payment-step3--subtitle mt-4">{t("app.profile.country")}</p>

            <select
              name="topic"
              value={userCountry}
              onChange={(e) => dispatch(setCountry(e.target.value))}
              className={`w-100 app__payment-step3--subtitle fw-semibold bg-transparent color-text-dark border-color1 px-3 py-3 mt-3 `}
              style={{
                borderRadius: "11px",
                outline: "none",
                appearance: "none",
                backgroundImage: `url(${selectArrow})`,
                backgroundPosition: "right 10px center",
                backgroundRepeat: "no-repeat",
              }}
            >
              <option value="fr">France</option>
              <option value="pl">Poland</option>
              <option value="ge">Germany</option>
              <option value="nl">Netherlands</option>
              <option value="it">Italy</option>
            </select>
          </div>
        </div>
      </div>
    </LayoutApp>
  );
};
