import React, { useEffect, useState } from "react";
import btcIcon from "../../../static/icons/app/home/btcIcon.svg";
import ethIcon from "../../../static/icons/app/home/ethIcon.svg";
import maticIcon from "../../../static/icons/app/home/maticIcon.svg";
import buyArrow from "../../../static/icons/app/home/buyArrow.svg";
import sellArrow from "../../../static/icons/app/home/sellArrow.svg";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import axios from "axios";

const backendUrl = process.env.REACT_APP_PUBLIC_API_BACKEND;

const transactionsList = [
  {
    id: 1,
    name: "Bitcoin",
    abr: "BTC",
    amount: "0.33",
    price: "$ 11.795,85",
    operation: "Buy",
    picture: btcIcon,
  },
  {
    id: 2,
    name: "Ethereum",
    abr: "ETH",
    amount: "2,21",
    price: "$ 5.248,75",
    operation: "Buy",
    picture: ethIcon,
  },
  {
    id: 3,
    name: "MATIC",
    abr: "MATIC",
    amount: "3.389,12",
    price: "$ 8.457,40",
    operation: "Sell",
    picture: maticIcon,
  },
  {
    id: 4,
    name: "MATIC",
    abr: "MATIC",
    amount: "3.389,12",
    price: "$ 8.457,40",
    operation: "Sell",
    picture: maticIcon,
  },
  {
    id: 5,
    name: "MATIC",
    abr: "MATIC",
    amount: "3.389,12",
    price: "$ 8.457,40",
    operation: "Sell",
    picture: maticIcon,
  },
];

export const Transactions = () => {
  const { t } = useTranslation();

  const token = localStorage.getItem("token");

  const [rowOrders, setRowOrders] = useState([]);
  const [isLoader, setIsLoader] = useState(true);

  useEffect(() => {
    FetchListOrder();
  }, []);

  const FetchListOrder = () => {
    const objForCreateSdkToken = {
      applicant_id: "",
      limit: 5,
    };

    axios
      .post(`${backendUrl}/order/listorder`, objForCreateSdkToken, {
        headers: {
          authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          setIsLoader(false);
          setRowOrders(response.data);
        }
      })

      .catch(function (error) {
        setIsLoader(false);
      });
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <span className="app__home-subtitle m-0 ps-3">
          {t("app.home.transactions")}
        </span>

        <Link
          to="/history"
          className="border-0 bg-transparent p-0 m-0 text-decoration-none"
          style={{
            color: "#777777",
            fontSize: "12px",
          }}
        >
          {t("app.home.viewAll")}
        </Link>
      </div>

      <div
        className="app__card rounded-4 px-3 py-2 py-md-2 my-3 my-xl-2"
        style={{
          // boxShadow: "0px 3.5px 3.5px 0px rgba(0, 0, 0, 0.25)",
        }}
      >
        {isLoader ? (
          <div
            className="border rounded d-flex flex-column mb-4"
            style={{
              boxShadow: "0px 0px 4px 2px rgba(0,0,0,0.1)",
              height: "100px",
              marginTop: "25px",
            }}
          >
            <div className="m-1 placeholder col-5"></div>
            <div className="m-1 placeholder col-7 placeholder-lg"></div>
            <div className="m-1 placeholder col-8"></div>
            <div className="m-1 placeholder col-4"></div>
          </div>
        ) : (
          rowOrders
            .slice(0, window.innerWidth > 1199 ? 3 : 6)
            .map((transaction: any) => (
              <div
                key={transaction.idOrders}
                className="d-flex align-items-center py-2"
              >
                <div className="app__home-transaction--icon">
                  <img
                    src={`https://assets.kraken.com/marketing/web/icons/sym-${transaction.o_currency.toLocaleLowerCase()}_colored.svg`}
                    alt="cryptoIcon"
                    style={{
                      height: "100%",
                    }}
                  />
                </div>

                <div className="ps-3 d-flex flex-column justify-content-between align-items-center w-100">
                  <div
                    className="d-flex justify-content-between align-items-center w-100"
                    style={{
                      lineHeight: "0.8rem",
                    }}
                  >
                    <span className="m-0 app__home-transaction--name fw-bold">
                      {transaction.o_currency}
                    </span>

                    <span className="m-0 app__home-transaction--price fw-bold">
                      {transaction.i_amount.toLocaleString("de-DE")}{" "}
                      {transaction.i_currency}
                    </span>
                  </div>
                </div>
              </div>
            ))
        )}

        {/* {transactionsList
          .slice(0, window.innerWidth > 1199 ? 2 : 5)
          .map((transaction) => (
            <div
              key={transaction.id}
              className="d-flex align-items-center py-2"
            >
              <div className="app__home-transaction--icon">
                <img
                  src={transaction.picture}
                  alt="cryptoIcon"
                  style={{
                    height: "100%",
                  }}
                />
              </div>

              <div className="ps-3 d-flex flex-column justify-content-between align-items-center pt-2 w-100">
                <div
                  className="d-flex justify-content-between align-items-center w-100"
                  style={{
                    lineHeight: "0.8rem",
                  }}
                >
                  <span className="m-0 app__home-transaction--name fw-bold">
                    {transaction.name}
                  </span>

                  <span className="m-0 app__home-transaction--price fw-bold">
                    {transaction.price}
                  </span>
                </div>

                <div className="d-flex justify-content-between align-items-center app__home-transaction--price w-100">
                  <div style={{ color: "#AB9F9F" }}>
                    <span className="m-0">{transaction.amount}</span>

                    <span className="m-0 ms-1 app__home-crypto-amount">
                      {transaction.abr}
                    </span>
                  </div>

                  <div>
                    <span
                      style={{
                        color:
                          transaction.operation === "Buy"
                            ? "#5ED5A8"
                            : "#FF4949",
                      }}
                    >
                      {transaction.operation === "Buy"
                        ? t("app.home.buy")
                        : t("app.home.sell")}
                    </span>

                    <img
                      src={
                        transaction.operation === "Buy" ? buyArrow : sellArrow
                      }
                      alt="operationArrow"
                    />
                  </div>
                </div>
              </div>
            </div>
          ))} */}
      </div>
    </div>
  );
};
