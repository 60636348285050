import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import facebookIcon from "../../static/icons/signIn/facebookIcon.png";
import googleIcon from "../../static/icons/signIn/googleIcon.png";
import registerBgLg from "../../static/images/Register/registerBgLg.png";
import arrowBack from "../../static/icons/signIn/arrowBack.svg";
import { useTranslation } from "react-i18next";
import { LanguagesBar } from "../Navbar/LanguagesBar"

interface IRegisterStep1 {
  setStep: (value: number) => void;
}

export const RegisterStep1: React.FC<IRegisterStep1> = ({ setStep }) => {
  const [visible, setVisible] = useState(false);

  const navigate = useNavigate();

  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, 100);
  }, []);

  const handleNextStep = () => {
    setVisible(false);

    setTimeout(() => {
      setStep(2);
    }, 1500);
  };

  const handleSingIn = () => {
    setVisible(false);

    setTimeout(() => {
      navigate("/signin");
    }, 1000);
  };

  const handleHome = () => {
    setVisible(false);

    setTimeout(() => {
      navigate("/");
    }, 1000);
  };

  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center py-2 py-lg-3 px-2 px-sm-4 px-lg-5 px-md-3"
        style={{
          borderBottom: "1px solid white",
          zIndex: 1,
          height: "60px",
          opacity: visible ? 1 : 0,
          transform: `scale(${visible ? 1 : 0.8})`,
          transition: "all 1.5s",
        }}
      >
        <button
          onClick={handleHome}
          className="text-white bg-transparent border-0 d-flex align-items-center signin--text-size"
        >
          <img src={arrowBack} alt="arrowBack" height={12} />
          <span className="ms-sm-4 ps-2">{t("logIn.back")}</span>
        </button>

        <div className="d-flex text-white signin--text-size">
          <LanguagesBar classProp="me-3" />

          <button
            onClick={handleSingIn}
            className={`text-white bg-transparent border-0 d-flex align-items-center ps-1`}
          >
            {t("register.step1.logIn")}
          </button>
        </div>
      </div>

      <div
        className="d-flex w-100 h-100 p-0 m-0"
        style={{
          flex: 1,
          opacity: visible ? 1 : 0,
          transform: `scale(${visible ? 1 : 0.8})`,
          transition: "all 1.5s",
        }}
      >
        <div
          className="row m-0 p-0 w-100 position-relative"
          style={{
            flex: 1,
          }}
        >
          <div
            className="col-12 col-lg-5 d-flex flex-column align-items-center align-items-lg-end signin--text-size my-lg-auto mt-5"
            style={{
              zIndex: 2,
            }}
          >
            <div
              className="signin__button--size"
              style={{
                maxHeight: "max-content",
              }}
            >
              <p className="fs-2 fw-semibold text-white text-center px-3 mb-5">
                {t("register.step1.title")}
              </p>

              {/* <Link
                to="/signin"
                className="d-flex align-items-center bg-white px-3 py-2 py-sm-3 rounded-5 text-decoration-none signin__button--size mt-4"
                style={{
                  boxShadow: "2px 2px 4px 0px rgba(0, 0, 0, 0.25)",
                  color: "rgba(0, 0, 0, 0.5)",
                }}
              >
                <div className="signin__icon">
                  <img
                    src={googleIcon}
                    alt="googleIcon"
                    style={{
                      width: "100%",
                    }}
                  />
                </div>

                <span className="ps-5">{t("logIn.continue")} Google</span>
              </Link>

              <Link
                to="/signin"
                className="d-flex align-items-center bg-white px-3 py-2 py-sm-3 rounded-5 text-decoration-none signin__button--size mt-3"
                style={{
                  boxShadow: "2px 2px 4px 0px rgba(0, 0, 0, 0.25)",
                  color: "rgba(0, 0, 0, 0.5)",
                }}
              >
                <div className="signin__icon">
                  <img
                    src={facebookIcon}
                    alt="facebookIcon"
                    style={{
                      width: "100%",
                    }}
                  />
                </div>

                <span className="ps-5">{t("logIn.continue")} Facebook</span>
              </Link>

              <div className="w-100 d-flex justify-content-between align-items-center my-2">
                <div
                  className="d-flex"
                  style={{
                    borderBottom: "1px solid #FFF",
                    height: "1px",
                    width: "40%",
                  }}
                />

                <p className="my-3" style={{ color: "#FFF", fontSize: "14px" }}>
                  {t("logIn.or")}
                </p>

                <div
                  className="d-flex"
                  style={{
                    borderBottom: "1px solid #FFF",
                    height: "1px",
                    width: "40%",
                  }}
                />
              </div> */}

              <button
                className="signin__button text-center text-white w-100 p-2 border-0 signin__button--size rounded-5"
                onClick={handleNextStep}
              >
                {t("register.step1.button")} email
              </button>

              <p
                className="px-2 mt-2"
                style={{
                  color: "#FFF",
                  fontSize: "14px",
                }}
              >
                {t("register.step1.sub.1")}{" "}
                <Link to="/policy" className="text-white">
                  {t("register.step1.sub.2")}
                </Link>
                {t("register.step1.sub.3")}{" "}
                <Link to="/conditions" className="text-white">
                  {t("register.step1.sub.4")}{" "}
                </Link>
                .
              </p>
            </div>
          </div>

          <div
            className="col-12 col-lg-7 d-flex justify-content-lg-end align-items-center p-0 register__step1"
            style={{
              overflow: "hidden",
            }}
          >
            <img
              src={registerBgLg}
              alt="registerBgLg"
              className="register__step1--image"
              style={{
                height: "100%",
                width: "100%",
                objectFit: "contain",
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
