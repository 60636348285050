import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { Formik, Field } from "formik";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import cornerLogo from "../../static/icons/CornerSwap_Logo.svg";
import step2Bg from "../../static/images/Register/step2Bg.png";
import arrowBack from "../../static/icons/signIn/arrowBack.svg";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { LanguagesBar } from "../Navbar/LanguagesBar";

interface IFormValide {
  email: string;
}

interface IRecoveryPasswordStep1 {
  setStepRecovery: (value: number) => void;
}

const backendUrl = process.env.REACT_APP_PUBLIC_API_BACKEND;

export const RecoveryPasswordStep1: React.FC<IRecoveryPasswordStep1> = ({
  setStepRecovery,
}) => {
  const [visible, setVisible] = useState(false);
  const [visibleGlob, setVisibleGlob] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
      setVisibleGlob(true);
    }, 100);
  }, []);

  const schema = yup.object().shape({
    email: yup.string().email("Invalid email").required(),
  });

  const ValideForm = (obj: IFormValide) => {
    setError(false);
    setLoading(true);

    axios
      .post(`${backendUrl}/users/recovery`, obj)
      .then((response) => {
        setLoading(false);

        if (response.status === 200) {
          setVisible(false);
          setVisibleGlob(false);

          setTimeout(() => {
            setStepRecovery(2);
          }, 1000);
        }
      })
      .catch((error) => {
        setError(true);
        setLoading(false);
      });
  };

  const handlePrevStep = () => {
    setVisible(false);
    setVisibleGlob(false);

    setTimeout(() => {
      navigate("/signin");
    }, 1000);
  };

  const handleSingIn = () => {
    setVisible(false);

    setTimeout(() => {
      navigate("/signin");
    }, 1000);
  };

  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center py-2 py-lg-3 px-2 px-sm-4 px-lg-5 px-md-3"
        style={{
          borderBottom: "1px solid white",
          zIndex: 1,
          height: "60px",
          opacity: visible ? 1 : 0,
          transition: "all 1.5s",
        }}
      >
        <div
          className="btn text-white text-decoration-none d-flex align-items-center p-0"
          onClick={handlePrevStep}
        >
          <img src={arrowBack} alt="arrowBack" height={12} />
          <span className="ms-sm-4 ps-2 signin--text-size">
            {t("logIn.back")}
          </span>
        </div>

        <div className="d-flex align-items-center text-white signin--text-size">
          <LanguagesBar classProp="me-3" />

          <span>{t("register.step2.haveAcc")}</span>

          <button
            onClick={handleSingIn}
            className="text-white bg-transparent border-0 d-flex align-items-center ps-1 text-decoration-underline"
          >
            {t("register.step1.logIn")}
          </button>
        </div>
      </div>

      <div
        className="d-flex w-100 h-100 p-0 m-0"
        style={{
          flex: 1,
        }}
      >
        <div className="container d-flex flex-column pt-5 position-relative">
          <div
            className="h-100"
            style={{
              zIndex: 1,
              opacity: visible ? 1 : 0,
              transform: `scale(${visible ? 1 : 0.8})`,
              transition: "all 1.5s",
            }}
          >
            <div className="text-center d-none d-md-block">
              <img src={cornerLogo} alt="cornerLogo" height={100} />
            </div>

            <div className="text-center d-md-none">
              <img src={cornerLogo} alt="cornerLogo" height={70} />
            </div>

            <div>
              <div className="d-flex align-items-center justify-content-center mt-4 mt-md-5">
                <span className="fs-2 fw-semibold text-white">
                  {t("recovery.title")}
                </span>
              </div>

              <div className="col-11 col-md-9 col-lg-5 p-0 d-flex flex-column justify-content-center mx-auto mt-3">
                <Formik
                  validationSchema={schema}
                  onSubmit={ValideForm}
                  initialValues={{
                    email: "",
                  }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    errors,
                  }) => (
                    <Form
                      className="my-3 text-center"
                      noValidate
                      onSubmit={handleSubmit}
                    >
                      <Row className="mb-3 mb-md-4 signin__button--size mx-auto">
                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="validationFormik03"
                          className="p-0"
                        >
                          <Field
                            className={`form-control signin--text-size px-4 py-2 py-sm-3 rounded-5 signin__button--size ${
                              errors.email &&
                              touched.email &&
                              "border border-danger"
                            } ${!errors.email && touched.email && "border border-success"}`}
                            style={{
                              boxShadow: "2px 2px 4px 0px rgba(0, 0, 0, 0.25)",
                              border: "1px solid transparent",
                              background: "#fff",
                            }}
                            id="email"
                            name="email"
                            value={values.email}
                            placeholder={t("logIn.email")}
                            type="email"
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Row>

                      <Button
                        type="submit"
                        className="signin__button signin__button--size w-100 border-0 p-2 rounded-5"
                      >
                        {loading ? (
                          <Spinner
                            animation="border"
                            size="sm"
                            variant="light"
                          />
                        ) : (
                          t("register.step2.button")
                        )}
                      </Button>
                    </Form>
                  )}
                </Formik>

                {error && (
                  <p className="text-danger text-center">
                    {t("recovery.uncorrect")}
                  </p>
                )}
              </div>
            </div>
          </div>

          <div
            className="register__step2 d-flex align-items-center justify-content-center"
            style={{
              opacity: visibleGlob ? 1 : 0,
              transition: "all 1.5s",
            }}
          >
            <img
              src={step2Bg}
              alt="step2Bg"
              className="register__step2--image"
            />
          </div>
        </div>
      </div>
    </>
  );
};
