import React, { useEffect, useRef, useState } from "react";
import tradePhone from "../../static/images/tradePhone.png";
import { useTranslation } from "react-i18next"

import convertIcon from "../../static/icons/business/convertIcon.png";
import chooseIcon from "../../static/icons/business/chooseIcon.png";
import pairsIcon from "../../static/icons/business/pairsIcon.png";

export const Trade = () => {
  const { t } = useTranslation();

  const [isVisible, setIsVisible] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const refs = useRef([
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
  ]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry, index) => {
          if (entry.isIntersecting) {
            setIsVisible((prevIsVisible) => {
              const newIsVisible = [...prevIsVisible];
              newIsVisible[index] = true;
              return newIsVisible;
            });
          }
        });
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    refs.current.forEach((ref) => {
      if (ref.current) {
        //@ts-ignore
        observer.observe(ref.current);
      }
    });

    return () => {
      refs.current.forEach((ref) => {
        if (ref.current) {
          //@ts-ignore
          observer.unobserve(ref.current);
        }
      });
    };
  }, [window.scrollY]);

  return (
    <div className="container mx-auto business__banking position-relative mt-5">
      <div
        className="d-none d-md-flex align-items-center business__banking-number position-absolute w-100"
        style={{
          opacity: isVisible[0] ? 1 : 0,
          transition: "all 0.8s ease-in-out",
        }}
        //@ts-ignore
        ref={refs.current[0]}
      >
        <div className="position-relative ps-3">
          <div style={{ width: "210px" }}>02</div>

          <span className="business__banking-sub position-absolute top-50 start-50 translate-middle w-100 ms-5">
            {t("business.trade.name")}
          </span>
        </div>

        <div
          className="ms-5"
          style={{
            flex: 1,
            borderTop: "3px solid #F5F5F5",
            zIndex: 2,
            height: "2px",
          }}
        ></div>
      </div>

      <p
        className="business__banking-title mt-3 m-lg-0 fw-bold"
        style={{
          opacity: isVisible[1] ? 1 : 0,
          transition: "all 0.8s ease-in-out",
          maxWidth: "500px",
        }}
        //@ts-ignore
        ref={refs.current[1]}
      >
        {t("business.trade.title")}
      </p>

      <div className="row m-0 p-0 justify-content-between mt-5">
        <div className="col-12 col-lg-6 p-0 pe-lg-5 position-relative">
          <p
            className="m-0"
            style={{
              color: "#6D6D6D",
              opacity: isVisible[2] ? 1 : 0,
              transition: "all 0.8s ease-in-out",
            }}
            //@ts-ignore
            ref={refs.current[2]}
          >
            {t("business.trade.sub")}
          </p>

          <div
            className="mt-5 p-4 d-flex align-items-center rounded-5"
            style={{
              background: "#F8F8F8",
              opacity: isVisible[3] ? 1 : 0,
              transition: "all 0.8s ease-in-out",
            }}
            //@ts-ignore
            ref={refs.current[3]}
          >
            <img
              src={convertIcon}
              loading="lazy"
              alt="convertIcon"
              height={32}
            />

            <span
              className="fs-5 fw-bold ms-3 inter__500"
              style={{ color: "#4D4D4D" }}
            >
              {t("business.trade.step.1")}
            </span>
          </div>

          <div
            className="mt-5 p-4 d-flex align-items-center rounded-5"
            style={{
              background: "#F8F8F8",
              opacity: isVisible[4] ? 1 : 0,
              transition: "all 0.8s ease-in-out",
            }}
            //@ts-ignore
            ref={refs.current[4]}
          >
            <img
              src={chooseIcon}
              loading="lazy"
              alt="chooseIcon"
              height={32}
            />

            <span
              className="fs-5 fw-bold ms-3 inter__500"
              style={{ color: "#4D4D4D" }}
            >
              {t("business.trade.step.2")}
            </span>
          </div>

          <div
            className="mt-5 p-4 d-flex align-items-center rounded-5"
            style={{
              background: "#F8F8F8",
              opacity: isVisible[5] ? 1 : 0,
              transition: "all 0.8s ease-in-out",
            }}
            //@ts-ignore
            ref={refs.current[5]}
          >
            <img
              src={pairsIcon}
              loading="lazy"
              alt="pairsIcon"
              height={32}
            />

            <span
              className="fs-5 fw-bold ms-3 inter__500"
              style={{ color: "#4D4D4D" }}
            >
              {t("business.trade.step.3")}
            </span>
          </div>
        </div>

        <div className="col-12 col-lg-6 p-0 pe-lg-5 mt-5 mt-lg-0"
          style={{
            opacity: isVisible[6] ? 1 : 0,
            transition: "all 0.8s ease-in-out",
          }}
          //@ts-ignore
          ref={refs.current[6]}
        >
          <img
            src={tradePhone}
            alt="tradePhone"
            style={{ width: "100%" }}
          />
        </div>
      </div>
    </div>
  );
};
