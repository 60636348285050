import React, { useState, useEffect } from "react";
import GloabalEtape1of3 from "../../components/Registration/GlobalEtape1of3";
import GloabalEtape3of3 from "../../components/Registration/GloabalEtape3of3";
// import Layout from "../components/aaaMainComponents/Layout";
import { useNavigate, useParams } from "react-router-dom";
import SumsubKYCParamsToken from "../../components/Registration/SumsubKYCParamsToken";
import { LanguagesBar } from "../../components/Navbar/LanguagesBar";
import step2Bg from "../../static/images/Register/step2Bg.png";
import cornerLogo from "../../static/icons/CornerSwap_Logo.svg";
import { useTranslation } from "react-i18next";

/**
 * ICI ON RECUPERE DANS L'URL de l'application le token, et on le stock dans le localStorage
 */
const Form = () => {
  let { token, step } = useParams();

  const [stateForm, setstateForm] = useState({
    step: step ? parseFloat(step) : 0,
  });

  useEffect(() => {
    token && localStorage.setItem("token", token);
  }, [token]);

  const stateStep = (arg: number) => {
    // function permettant de switcher entre tous les composants du formulaire
    setstateForm((prevState) => ({ ...prevState, step: prevState.step + arg }));
  };

  const [visible, setVisible] = useState(false);

  const navigate = useNavigate();

  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, 100);
  }, []);

  const handleSingIn = () => {
    setVisible(false);

    setTimeout(() => {
      navigate("/signin");
    }, 1000);
  };

  return (
    <div
      className="d-flex flex-column h-100 w-100 m-0 p-0 position-relative"
      style={{ minHeight: "100vh", minWidth: "100vw", background: "#252430" }}
    >
      <div
        className="d-flex justify-content-end align-items-center py-2 py-lg-3 px-2 px-sm-4 px-lg-5 px-md-3"
        style={{
          borderBottom: "1px solid white",
          zIndex: 1,
          height: "60px",
          opacity: visible ? 1 : 0,
          transition: "all 1.5s",
        }}
      >
        <div className="d-flex align-items-center text-white signin--text-size">
          <LanguagesBar classProp="me-3" />

          <span>{t("register.step2.haveAcc")}</span>

          <button
            onClick={handleSingIn}
            className="text-white bg-transparent border-0 d-flex align-items-center ps-1 text-decoration-underline"
          >
            {t("register.step1.logIn")}
          </button>
        </div>
      </div>

      <div
        className="d-flex w-100 h-100 p-0 m-0"
        style={{
          flex: 1,
        }}
      >
        <div
          className="container d-flex flex-column pt-5 position-relative"
          style={{
            flexGrow: 1,
          }}
        >
          <div
            style={{
              zIndex: 1,
              opacity: visible ? 1 : 0,
              transform: `scale(${visible ? 1 : 0.8})`,
              transition: "all 1.5s",
            }}
          >
            <div className="text-center d-none d-md-block">
              <img src={cornerLogo} alt="cornerLogo" height={100} />
            </div>

            <div className="text-center d-md-none">
              <img src={cornerLogo} alt="cornerLogo" height={70} />
            </div>

            <p className="fs-2 fw-semibold text-white text-center m-0 mb-1 mt-5">
              Congratulation! There are a few steps left to complete your registration.
            </p>

            <div className="container">
          <div
            className="row justify-content-center align-items-center"
          >
            {/* Ceci permet d'utiliser le col 8 que lorsque que onfido est ouvert */}
            <div className="col-md-8 col-11 text-center p-3">

              {stateForm.step === 0 &&      // globalEtape1
                <GloabalEtape1of3 stateStep={stateStep} />
              }

              {stateForm.step === 1 && // GloabalEtape3of3
                <SumsubKYCParamsToken stateStep={stateStep} token={token} />
              }

              {stateForm.step === 2 && // GloabalEtape3of3
                <GloabalEtape3of3 token={token} />
              }

            </div>
          </div>
        </div>
          </div>

          <div className="register__step2 d-flex align-items-center justify-content-center">
            <img
              src={step2Bg}
              alt="step2Bg"
              className="register__step2--image"
            />
          </div>
        </div>
      </div>
    </div>

    // <div className="container"
    // >
    //   <div className={`container-fluid  bg-light position-relative`}>
    //     <LanguagesBar
    //       classProp={"ps-3 pt-4 text-dark drop-language d-flex justify-content-end"}
    //     />

    //     <div className="container">
    //       <div
    //         className="row justify-content-center align-items-center"
    //         style={{ minHeight: "100vh" }}
    //       >
    //         {/* Ceci permet d'utiliser le col 8 que lorsque que onfido est ouvert */}
    //         <div className="col-md-8 col-11 text-center p-3">

    //           {stateForm.step === 0 &&      // globalEtape1
    //             <GloabalEtape1of3 stateStep={stateStep} />
    //           }

    //           {stateForm.step === 1 && // GloabalEtape3of3
    //             <SumsubKYCParamsToken stateStep={stateStep} token={token} />
    //           }

    //           {stateForm.step === 2 && // GloabalEtape3of3
    //             <GloabalEtape3of3 token={token} />
    //           }

    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default Form;
