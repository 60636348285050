import React, { useEffect, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import changeCountryIcon from "../../../static/icons/app/trade/changeCountryIcon.svg";
import applePayIcon from "../../../static/icons/app/trade/applePayIcon.svg";
import googlePayIcon from "../../../static/icons/app/trade/googlePayIcon.svg";
import cardIcon from "../../../static/icons/app/trade/cardIcon.svg";
import bankIcon from "../../../static/icons/app/trade/bankIcon.svg";
import fastIcon from "../../../static/icons/app/trade/fastIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../../redux/store/store";
import {
  setBankBic,
  setBankIban,
  setIbanNumber,
  setOrderReference,
  setPaymentType,
  setResetOrder,
  setStockOrderId,
  setWalletAdress,
} from "../../../redux/slices/order.slice";
import { useTranslation } from "react-i18next";
//@ts-ignore
import IBAN from "iban";
import axios from "axios";

const backendUrl = process.env.REACT_APP_PUBLIC_API_BACKEND;

interface IPayment {
  setStep: (value: number) => void;
}

export const Payment: React.FC<IPayment> = ({ setStep }) => {
  const { t } = useTranslation();

  const [acceptTerms, setAcceptTerms] = useState(false);
  const [acceptPolicy, setAcceptPolicy] = useState(false);
  const [wrongIban, setWrongIban] = useState(false);

  const [country, setCountry] = useState("UK");

  const {
    walletAdress,
    ibanNumber,
    inputAmountBuy,
    inputCurrencyBuy,
    outputAmountBuy,
    outputCurrencyBuy,
  } = useSelector(({ orderData }: IRootState) => orderData);

  const { token } = useSelector(({ authData }: IRootState) => authData.fetchAuth);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setResetOrder())
  }, [])

  const handleNextStep = () => {
    setWrongIban(false);

    if (IBAN.isValid(ibanNumber)) {
      console.log("done");
      setStep(2)
    } else {
      console.log("Wrong IBAN");
      setWrongIban(true);

      return;
    }

    const HeaderPost = {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    const orderBody = {
      input: {
        amount: inputAmountBuy,
        currency: inputCurrencyBuy.maj,
        iban: ibanNumber,
        type: "bank_account",
      },
      output: {
        devis_amount: outputAmountBuy,
        crypto_address: walletAdress,
        currency: outputCurrencyBuy,
        type: "crypto_address",
      },
    };

    axios
      .post(`${backendUrl}/order/orderG`, orderBody, HeaderPost)
      .then((response) => {
        dispatch(setBankIban(response.data.bank.iban))
        dispatch(setBankBic(response.data.bank.bic))
        dispatch(setOrderReference(response.data.dataOrder.ref))
        dispatch(setStockOrderId(response.data.dataOrder.idOrders))
      })
      .catch((error) => console.log(error));
  };

  return (
    <div
      className="app__card rounded-4 h-100 px-4 py-2 py-xl-3 my-3 my-xl-0 mb-xl-2"
    >
      <div>
        <div className="d-flex align-items-center justify-content-between">
          <span className="app__payment-step--title">
            {t("app.trade.step.1")}
          </span>
          <div
            style={{
              width: "88%",
            }}
          >
            <ProgressBar
              now={33}
              label={55}
              visuallyHidden
              variant="#FFF"
              style={{
                height: "2.6px",
                background: "#D9D9D9",
              }}
            />
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-between mt-3 mt-md-4">
          <span className="app__payment--method">
            {t("app.trade.paymentMethod")}
          </span>
        </div>

        <p
          className="m-0 mt-3"
          style={{
            color: "#989898",
            fontFamily: "Inter 600!important",
          }}
        >
          {t("app.trade.step.wallet")}
        </p>

        <div className="px-2 py-1 mt-1 background-input-white border rounded-4 col-12 col-sm-10 col-md-8">
          <input
            className="w-100 background-input-white"
            style={{ outline: "none", border: "none", fontSize: "12px" }}
            type="text"
            value={walletAdress.trim()}
            onChange={(e) => dispatch(setWalletAdress(e.target.value))}
          />
        </div>

        <p
          className="m-0 mt-3"
          style={{
            color: "#989898",
            fontFamily: "Inter 600!important",
          }}
        >
          {t("app.trade.step.iban")}
        </p>

        <div className="px-2 py-1 mt-1 background-input-white border rounded-4 col-12 col-sm-10 col-md-8">
          <input
            className="w-100 background-input-white"
            style={{ outline: "none", border: "none", fontSize: "12px" }}
            type="text"
            value={ibanNumber.trim()}
            onChange={(e) => dispatch(setIbanNumber(e.target.value))}
          />
        </div>

        {wrongIban && (
          <p
            className="m-0 ps-2 pt-1 text-danger"
            style={{
              color: "#989898",
              fontFamily: "Inter 600!important",
              fontSize: "12px",
            }}
          >
            {t("app.trade.step.ibanError")}
          </p>
        )}

        <div className="d-flex align-items-center mt-3">
          <input
            type="checkbox"
            checked={acceptTerms}
            onChange={() => setAcceptTerms(!acceptTerms)}
          />

          <span
            className="ms-2"
            style={{
              color: "#989898",
              fontFamily: "Inter 600!important",
              fontSize: "14px",
            }}
          >
            {t("app.trade.step.terms")}
          </span>
        </div>

        <div className="d-flex align-items-center mt-3">
          <input
            type="checkbox"
            checked={acceptPolicy}
            onChange={() => setAcceptPolicy(!acceptPolicy)}
          />

          <span
            className="ms-2"
            style={{
              color: "#989898",
              fontFamily: "Inter 600!important",
              fontSize: "14px",
            }}
          >
            {t("app.trade.step.privacy")}
          </span>
        </div>

        {/* <div className="mt-5 d-flex justify-content-between align-items-center app__payment-country">
          <div>
            <span
              style={{
                color: "#989898",
                fontFamily: "Inter 600!important",
              }}
            >
              {t("app.trade.addMethod")}
            </span>

            <span
              className="fw-bold ms-2"
              style={{
                color: "#DBB1F6",
              }}
            >
              {country}
            </span>
          </div>

          <div className="btn">
            <span
              className="fw-bold me-1 app__payment-country"
              style={{
                color: "#989898",
              }}
            >
              {t("app.trade.changeCountry")}
            </span>

            <img src={changeCountryIcon} alt="changeCountryIcon" height={10} />
          </div>
        </div> */}

        {/* <div className="pt-3">
          <div
            className="btn app__payment-link d-flex justify-content-between align-items-center rounded-5 px-3"
            onClick={() => dispatch(setPaymentType("ApplePay"))}
            style={{
              border:
                paymentType === "ApplePay"
                  ? "3.3px solid #b262e4"
                  : "1.3px solid #DBB1F6",
              background:
                "linear-gradient(to right, white, white 40%, #DBB1F6)",
            }}
          >
            <div className="d-flex align-items-center">
              <div className="app__payment-link--icon d-flex">
                <img
                  src={applePayIcon}
                  alt="applePayIcon"
                  style={{
                    height: "100%",
                  }}
                />
              </div>

              <div className="d-flex flex-column text-start">
                <span className="app__payment-link--title">Apple Pay</span>
                <span className="app__payment-link--subtitle">
                  {t("app.trade.arrives.immediately")}
                </span>
              </div>
            </div>

            <div className="app__payment-link--icon-fast">
              <img
                src={fastIcon}
                alt="fastIcon"
                style={{
                  height: "100%",
                }}
              />
            </div>
          </div>

          <div
            className="btn app__payment-link d-flex justify-content-between align-items-center rounded-5 px-3 mt-3"
            onClick={() => dispatch(setPaymentType("GooglePay"))}
            style={{
              border:
                paymentType === "GooglePay"
                  ? "3.3px solid #b262e4"
                  : "1.3px solid #DBB1F6",
              background:
                "linear-gradient(to right, white, white 40%, #DBB1F6)",
            }}
          >
            <div className="d-flex align-items-center">
              <div className="app__payment-link--icon d-flex">
                <img
                  src={googlePayIcon}
                  alt="googlePayIcon"
                  style={{
                    height: "100%",
                  }}
                />
              </div>

              <div className="d-flex flex-column text-start">
                <span className="app__payment-link--title">Goggle Pay</span>
                <span className="app__payment-link--subtitle">
                {t("app.trade.arrives.immediately")}
                </span>
              </div>
            </div>

            <div className="app__payment-link--icon-fast">
              <img
                src={fastIcon}
                alt="fastIcon"
                style={{
                  height: "100%",
                }}
              />
            </div>
          </div>

          <div
            className="btn app__payment-link d-flex justify-content-between align-items-center rounded-5 px-3 mt-3"
            onClick={() => dispatch(setPaymentType("Card"))}
            style={{
              border:
                paymentType === "Card"
                  ? "3.3px solid #b262e4"
                  : "1.3px solid #D4D4D4",
            }}
          >
            <div className="d-flex align-items-center">
              <div className="app__payment-link--icon d-flex ps-2">
                <img
                  src={cardIcon}
                  alt="cardIcon"
                  style={{
                    height: "100%",
                  }}
                />
              </div>

              <div className="d-flex flex-column text-start">
                <span className="app__payment-link--title">Card</span>
                <span className="app__payment-link--subtitle">
                  {t("app.trade.arrives.minutes")}
                </span>
              </div>
            </div>

            <div className="app__payment-link--icon-fast">
              <img
                src={fastIcon}
                alt="fastIcon"
                style={{
                  height: "100%",
                }}
              />
            </div>
          </div>

          <div
            className="btn app__payment-link d-flex justify-content-between align-items-center rounded-5 px-3 mt-3"
            onClick={() => dispatch(setPaymentType("BankTransfer"))}
            style={{
              border:
                paymentType === "BankTransfer"
                  ? "3.3px solid #b262e4"
                  : "1.3px solid #D4D4D4",
            }}
          >
            <div className="d-flex align-items-center">
              <div
                className="app__payment-link--icon d-flex"
                style={{
                  paddingLeft: "14px",
                }}
              >
                <img
                  src={bankIcon}
                  alt="bankIcon"
                  style={{
                    height: "100%",
                  }}
                />
              </div>

              <div className="d-flex flex-column text-start">
                <span className="app__payment-link--title">Bank transfer</span>
                <span className="app__payment-link--subtitle">
                  {t("app.trade.arrives.hours")}
                </span>
              </div>
            </div>

            <div className="app__payment-link--icon-fast">
              <img
                src={fastIcon}
                alt="fastIcon"
                style={{
                  height: "100%",
                }}
              />
            </div>
          </div>
        </div> */}
      </div>

      <div className="w-100 p-0 d-flex justify-content-end mt-5">
        <button
          className="app__payment-button text-white d-flex justify-content-center align-items-center border-0 rounded-5"
          style={{
            background:
              !acceptTerms ||
              !acceptPolicy ||
              !walletAdress.length ||
              !ibanNumber.length
                ? "#e6cdf7"
                : "#DBB1F6",
          }}
          disabled={
            !acceptTerms ||
            !acceptPolicy ||
            !walletAdress.length ||
            !ibanNumber.length
          }
          onClick={handleNextStep}
        >
          {t("app.home.exchange.button")}
        </button>
      </div>
    </div>
  );
};
