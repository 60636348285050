import React from "react";
import { useTranslation } from "react-i18next";

interface ISubscribe {
  bgColor: string;
  classProp: string;
}

export const Subsribe: React.FC<ISubscribe> = ({ bgColor, classProp }) => {
  const { t } = useTranslation();

  return (
    <div
      className={`input-group d-flex rounded-2 ${classProp}`}
      style={{
        background: `${bgColor}`,
      }}
    >
      <div className="d-flex justify-content-between align-items-center px-2 px-sm-4 py-3">
        <div className="d-flex flex-column flex-lg-row m-0 align-items-lg-center">
          <span className="text-dark blog__title--sub-1 fw-bold m-0 p-0 pe-2">
            {t("blog.subscribe.title")}
          </span>

          <span
            className="text-dark blog__title--sub-1 fw-bold m-0 p-0"
            style={{
              background: "#D8AFF3",
              lineHeight: "1.2",
            }}
          >
            {t("blog.subscribe.news")}
          </span>
        </div>

        <p className="text-dark m-0 d-flex justify-content-center col-5 pe-xl-3 blog__title--sub">
          {t("blog.subscribe.sub")}
        </p>
      </div>

      <div
        className="w-100 mb-3"
        style={{
          border: "1px solid #DDE1E2",
        }}
      />

      <div className="w-100 row justify-content-between ps-sm-3 pe-2 pe-sm-4 py-2">
        <input
          type="email"
          className="border-0 col-8 rounded subscribe__input bg-transparent"
          placeholder={t("blog.subscribe.placeholder")}
          aria-label="Recipient's email"
          aria-describedby="button-addon2"
        />

        <button
          className="text-dark border-0 bg-transparent subscribe__button text-decoration-underline p-0 me-md-3"
          style={{
            width: "max-content",
          }}
          type="button"
          id="button-addon2"
        >
          {t("blog.subscribe.button")}
        </button>
      </div>

      <div
        className="w-100 my-3"
        style={{
          border: "1px solid #DDE1E2",
        }}
      />

      <div className="d-flex align-items-center mb-4">
        <input type="checkbox" className="ms-2 ms-lg-4" />

        <span className="text-dark ms-2 blog__title--sub">
        {t("blog.subscribe.checkBox")}
        </span>
      </div>
    </div>
  );
};
