import React from "react";
import { ProgressBar } from "react-bootstrap";
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router"

const backendUrl = process.env.REACT_APP_PUBLIC_API_BACKEND;

export const SuccessOrder = () => {
  const navigate = useNavigate();


  const { t } = useTranslation();

  const handleConfirm = () => {
    navigate("/home")
  }

  return (
    <div
      className="app__card rounded-4 px-4 py-2 pt-xl-4 pb-xl-5 mb-3"
    >
      <div className="d-flex align-items-center justify-content-between mt-4">
        <span className="app__payment-step--title">
          {t("app.trade.step.3")}
        </span>
        <div
          style={{
            width: "88%",
          }}
        >
          <ProgressBar
            now={100}
            label={55}
            visuallyHidden
            variant="#FFF"
            style={{
              height: "2.6px",
              background: "#D9D9D9",
            }}
          />
        </div>
      </div>

      <div className="mb-4">
        <p className="app__payment--method text-center mt-3 mt-md-4">{t("app.trade.step.complete")}</p>

        <div className="w-100 p-0 d-flex justify-content-center mt-5">
          <button
            className="app__payment-button text-white d-flex justify-content-center align-items-center border-0 rounded-5"
            style={{
              background: "#DBB1F6",
            }}
            onClick={handleConfirm}
          >
            {t("app.trade.step.button")}
          </button>
        </div>
      </div>
    </div>
  );
};
