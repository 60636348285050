import React from "react";
import method from "../../static/icons/onRamp/method.svg";
import range from "../../static/icons/onRamp/range.svg";
import security from "../../static/icons/onRamp/security.svg";
import { Link } from "react-router-dom";
import arrowRight from "../../static/icons/onRamp/arrowRight.svg";
import { useTranslation } from "react-i18next";
import { Nav } from "react-bootstrap";

const blogLink = process.env.REACT_APP_LINK_BLOG;

export const Integration = () => {
  const { t } = useTranslation();

  return (
    <div className="container mx-auto my-5 p-0 py-md-5">
      <p className="onramp__title--size text-center m-0">
        {t("onRamp.integration.title")}
      </p>

      <div className="row m-0 p-0 pt-5">
        <div className="col-12 col-md-6 col-lg-4 p-2">
          <div
            className="border border-dark onramp__card p-4 h-100"
            style={{
              background: "#F5F5F5",
            }}
          >
            <div className="row justify-content-between p-0 m-0">
              <div className="col-9 col-md-12 p-0">
                <div className="d-flex justify-content-between">
                  <span className="onramp__title--size fw-bold pt-3">
                    {t("onRamp.integration.card.1.title")}
                  </span>

                  <img
                    src={method}
                    alt="method"
                    className="mt-2 mt-md-3 mt-xl-4 d-none d-md-block onramp__card-icon"
                  />
                </div>

                <p className="mt-1 mt-md-3 onramp__text">
                  {t("onRamp.integration.card.1.text")}
                </p>
              </div>

              <div className="col-2 p-0 d-flex align-items-center justify-content-end d-md-none">
                <img
                  src={method}
                  alt="method"
                  className="mt-2 mt-md-3 mt-xl-4 onramp__card-icon"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-6 col-lg-4 p-2">
          <div
            className="border border-dark onramp__card p-4 h-100"
            style={{
              background: "#F5F5F5",
            }}
          >
            <div className="row justify-content-between p-0 m-0">
              <div className="col-9 col-md-12 p-0">
                <div className="d-flex justify-content-between">
                  <span className="onramp__title--size fw-bold pt-3">
                    {t("onRamp.integration.card.2.title")}
                  </span>

                  <img
                    src={range}
                    alt="range"
                    className="mt-2 mt-md-3 mt-xl-4 d-none d-md-block onramp__card-icon"
                  />
                </div>

                <p className="mt-1 mt-md-3 onramp__text">
                  {t("onRamp.integration.card.2.text")}
                </p>
              </div>

              <div className="col-2 p-0 d-flex align-items-center justify-content-end d-md-none">
                <img
                  src={range}
                  alt="range"
                  className="mt-2 mt-md-3 mt-xl-4 onramp__card-icon"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-6 col-lg-4 p-2">
          <div
            className="border border-dark onramp__card p-4 h-100"
            style={{
              background: "#F5F5F5",
            }}
          >
            <div className="row justify-content-between p-0 m-0">
              <div className="col-9 col-md-12 p-0">
                <div className="d-flex justify-content-between">
                  <span className="onramp__title--size fw-bold pt-3">
                    {t("onRamp.integration.card.3.title")}
                  </span>

                  <img
                    src={security}
                    alt="security"
                    className="mt-2 mt-md-3 mt-xl-4 d-none d-md-block onramp__card-icon"
                  />
                </div>

                <p className="mt-1 mt-md-3 onramp__text">
                  {t("onRamp.integration.card.3.text")}
                </p>
              </div>

              <div className="col-2 p-0 d-flex align-items-center justify-content-end d-md-none">
                <img
                  src={security}
                  alt="security"
                  className="mt-2 mt-md-3 mt-xl-4 onramp__card-icon"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <p className="onramp__title--size text-center m-0 mt-5 px-2 pt-md-5">
        {t("onRamp.integration.check")}
      </p>

      <div className="px-2 mt-4 mt-md-5">
        {!!blogLink?.length && (
          <Nav.Link
            href={blogLink}
            className="row m-0 p-2 py-4 p-sm-4 w-100 border border-dark onramp__card d-flex justify-content-between text-decoration-none text-dark"
            style={{
              background: "#F5F5F5",
            }}
          >
            <div className="col-9 p-0 ps-3">
              <p
                className="onramp__title--size mt-3"
                style={{
                  fontFamily: "Inter, sans-serif",
                  lineHeight: "10px",
                  fontWeight: 600,
                }}
              >
                {t("onRamp.integration.link.1.title")}
              </p>

              <p
                className="m-0 mt-4 onramp__text"
                style={{
                  fontFamily: "Inter, sans-serif",
                }}
              >
                {t("onRamp.integration.link.1.text")}{" "}
              </p>
            </div>

            <div
              className="col-3 d-flex align-items-center justify-content-end
          p-0 pe-3 mt-3 mt-sm-0"
            >
              <img
                src={arrowRight}
                alt="arrowRight"
                className="onramp__card-icon"
              />
            </div>
          </Nav.Link>
        )}

        <Link
          to="/faq"
          className="row m-0 mt-3 p-2 py-4 p-sm-4 w-100 border border-dark onramp__card d-flex justify-content-between text-decoration-none text-dark"
          style={{
            background: "#F5F5F5",
          }}
        >
          <div className="col-9 p-0 ps-3">
            <p
              className="onramp__title--size mt-3"
              style={{
                fontFamily: "Inter, sans-serif",
                lineHeight: "10px",
                fontWeight: 600,
              }}
            >
              {t("onRamp.integration.link.2.title")}
            </p>

            <p
              className="m-0 mt-4 onramp__text"
              style={{
                fontFamily: "Inter, sans-serif",
              }}
            >
              {t("onRamp.integration.link.2.text")}
            </p>
          </div>

          <div
            className="col-3 d-flex align-items-center justify-content-end
          p-0 pe-3 mt-3 mt-sm-0"
          >
            <img
              src={arrowRight}
              alt="arrowRight"
              className="onramp__card-icon"
            />
          </div>
        </Link>
      </div>
    </div>
  );
};
