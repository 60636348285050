import React, { useEffect, useState } from "react";
import cornerLogoBlack from "../../static/icons/CornerSwapFinal.svg";
import { useDispatch, useSelector } from "react-redux";
import { setListCryptocurrency } from "../../redux/slices/order.slice";
import axios from "axios";
import { IRootState } from "../../redux/store/store";
import { useTranslation } from "react-i18next";
import { Subsribe } from "../Blog/Subsribe";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom"

const logInLink = process.env.REACT_APP_LINK_LOGIN;
const blogLink = process.env.REACT_APP_LINK_BLOG;

const top10 = [
  {
    name: "Bitcoin",
    blockchain: "Bitcoin, Lightning",
    search: "BTC",
    altname: "BTCEUR",
  },
  {
    name: "Ethereum ('Ether')",
    blockchain: "Ethereum (ERC-20)",
    search: "ETH",
    altname: "ETHEUR",
  },
  {
    name: "Tether",
    blockchain: "Ethereum (ERC-20)",
    search: "USDT",
    altname: "USDTEUR",
  },
  {
    name: " Binance Coin",
    blockchain: "Binance chain",
    search: "BNB",
    altname: "BNBEUR",
  },
  {
    name: "Solana",
    blockchain: "Solana (SPL)",
    search: "SOL",
    altname: "SOLEUR",
  },
  {
    name: "Ripple",
    blockchain: "BNB Smart Chain",
    search: "XRP",
    altname: "XRPEUR",
  },
  {
    name: "US Dollar Coin",
    blockchain: "Ethereum (ERC-20)",
    search: "USDC",
    altname: "USDCEUR",
  },
  {
    name: "Cardano",
    blockchain: "Cardano",
    search: "ADA",
    altname: "ADAEUR",
  },
  {
    name: "Avalanche",
    blockchain: "C-Chain",
    search: "AVAX",
    altname: "AVAXEUR",
  },
  {
    name: "Dogecoin ",
    blockchain: "Ethereum (ERC-20)",
    search: "DOGE",
    altname: "DOGEEUR",
  },
];

const Footer = () => {
  const { t } = useTranslation();

  const listCrypto = useSelector(
    ({ orderData }: IRootState) => orderData.listCryptocurrency
  );

  const [category, setCategory] = useState(10);
  const [mobileScreen, setMobileScreen] = useState(false);
  const [more, setMore] = useState(4);

  const { colorFirst, colorSecond, colorTextSecond } = useSelector(
    ({ styleData }: IRootState) => styleData
  );

  const dispatch = useDispatch();

  useEffect(() => {
    setMobileScreen(window.innerWidth < 768);

    const objectToPost = {
      input: "ZEUR",
    };
    const backendUrl = process.env.REACT_APP_PUBLIC_API_BACKEND;

    axios
      .post(`${backendUrl}/pb/pairsByDevice`, objectToPost)
      .then(function (response: any) {
        if (response.status === 200) {
          dispatch(setListCryptocurrency(Object.values(response?.data)));
        }
      })
      .catch(function (error: any) {
        console.log("error :", error);
      });
  }, []);

  useEffect(() => {
    function handleResize() {
      const screenWidth = window.innerWidth < 768;

      screenWidth !== mobileScreen && setMobileScreen(screenWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [mobileScreen]);

  useEffect(() => {
    if (!Array.isArray(listCrypto)) {
      const arrayCrypto = Object.entries(listCrypto).map(
        ([key, value]: any) => ({
          ...value,
          id: key,
        })
      );

      dispatch(setListCryptocurrency(arrayCrypto));
    }
  }, [listCrypto]);

  const handleMore = () => {
    if (more + 2 < category) {
      setMore((prevState) => prevState + 2);
    } else {
      setMore(4);
    }
  };

  return (
    <div
      className="w-100 m-0 p-0"
      style={{
        minHeight: "100vh",
        background: "rgba(22, 51, 0, .078)",
        zIndex: "999",
      }}
    >
      <div className="w-100 mx-auto px-2 pt-5" style={{ maxWidth: "1320px" }}>
        <p className="fs-1 fw-bold m-0 pt-3">{t("footer.title")}</p>

        <div className="mt-5">
          <button
            onClick={() => {
              setCategory(10);
              setMore(4);
            }}
            className="fw-bold text-decoration-none py-2 px-4 text-center rounded-5 me-3"
            style={{
              border: `1px solid black`,
              backgroundColor: category === 10 ? colorSecond : "",
            }}
          >
            {t("footer.top.10")}
          </button>

          <button
            onClick={() => {
              setCategory(listCrypto?.length);
              setMore(4);
            }}
            className="fw-bold text-decoration-none py-2 px-4 text-center rounded-5"
            style={{
              border: `1px solid black`,
              backgroundColor:
                category === listCrypto?.length ? colorSecond : "",
            }}
          >
            {t("footer.top.all")}
          </button>
        </div>

        <div className="row d-flex justify-content-between flex-wrap m-0 mt-2">
          {category === 10 &&
            top10
              .slice(0, mobileScreen ? more : top10.length)
              .map((cryptoInfo: any, index: number) => (
                <div
                  className="col-6 col-sm-6 col-md-4 col-lg-2 me-lg-4 mt-5"
                  key={index}
                >
                  <img
                    //@ts-ignore
                    src={`https://assets.kraken.com/marketing/web/icons/sym-${cryptoInfo?.search?.toLowerCase()}_colored.svg`}
                    alt="logo"
                    style={{ height: "48px" }}
                  />

                  <p
                    className="m-0 fw-bold mt-3"
                    style={{ color: colorTextSecond }}
                  >
                    {cryptoInfo?.name}
                  </p>

                  <p className="m-0" style={{ color: colorTextSecond }}>
                    {cryptoInfo?.blockchain}
                  </p>

                  <Link
                    to={`/cryptoInfo/${cryptoInfo?.altname}`}
                    className="m-0 fw-bold text-dark"
                  >
                    {t("footer.crypto.1")} {cryptoInfo?.search}{" "}
                    {t("footer.crypto.2")}
                  </Link>
                </div>
              ))}

          {listCrypto &&
            category === listCrypto?.length &&
            listCrypto
              ?.slice(0, mobileScreen ? more : listCrypto?.length)
              .map((cryptoInfo: any, index: number) => (
                <div
                  className="col-6 col-sm-6 col-md-4 col-lg-2 me-lg-4 mt-5"
                  key={index}
                >
                  <img
                    //@ts-ignore
                    src={`https://assets.kraken.com/marketing/web/icons/sym-${cryptoInfo?.search?.toLowerCase()}_colored.svg`}
                    alt="logo"
                    style={{ height: "48px" }}
                  />

                  <p
                    className="m-0 fw-bold mt-3"
                    style={{ color: colorTextSecond }}
                  >
                    {cryptoInfo?.name}
                  </p>

                  <p className="m-0" style={{ color: colorTextSecond }}>
                    {cryptoInfo?.blockchain}
                  </p>

                  <Link
                    to={`/cryptoInfo/${cryptoInfo?.altname}`}
                    className="m-0 fw-bold text-dark"
                  >
                    Get {cryptoInfo?.search} account details
                  </Link>
                </div>
              ))}

          {mobileScreen && (
            <button
              onClick={handleMore}
              className="mt-5 border-0 bg-transparent fw-bold text-decoration-underline"
            >
              {more < category ? "Show more" : "Show less"}
            </button>
          )}
        </div>
      </div>

      <div className="" style={{ background: "#ECEFF5" }}>
        <div
          className="w-100 mx-auto px-2 py-3 my-2 my-md-5"
          style={{ maxWidth: "1320px" }}
        >
          <Subsribe bgColor="#ECEFF5" classProp="" />
        </div>
      </div>

      <div className="w-100 mx-auto px-2 pb-5" style={{ maxWidth: "1320px" }}>
        <div
          className="w-100 px-5 py-5 row justify-content-center mx-auto"
          style={{ marginTop: "100px", borderBottom: "1px solid #6c757d" }}
        >
          <div className="d-md-none text-center mb-5">
            <img
              src={cornerLogoBlack}
              alt="cornerLogoBlack"
              style={{
                height: "60px",
              }}
            />
          </div>

          <Nav className="col-12 col-md-4 col-lg-2 d-flex flex-column align-items-center align-items-md-start">
            <p className="m-0 fw-bold mb-3">{t("footer.categories.company")}</p>

            <Link
              to="/about"
              className="m-0 p-0 text-decoration-underline"
              style={{ color: "black" }}
            >
              {t("footer.links.about")}
            </Link>

            {!!blogLink?.length && (
              <Link
                to={blogLink}
                className="m-0 p-0 text-decoration-underline"
                style={{ color: "black" }}
              >
                {t("footer.links.learn")}
              </Link>
            )}
          </Nav>

          <Nav className="col-12 col-md-4 col-lg-2 d-flex flex-column align-items-center align-items-md-start mt-4 mt-md-0">
            <p className="m-0 fw-bold mb-3">
              {t("footer.categories.personal")}
            </p>

            <Link
              // to={logInLink ? logInLink : "/"}
              to="/signin"
              className="m-0 p-0 text-decoration-underline"
              style={{ color: "black" }}
            >
              {t("footer.links.buy")}
            </Link>
            <Link
              to="/signin"
              // to={logInLink ? logInLink : "/"}
              className="m-0 p-0 text-decoration-underline"
              style={{ color: "black" }}
            >
              {t("footer.links.sell")}
            </Link>
            <Link
              to="/signin"
              // to={logInLink ? logInLink : "/"}
              className="m-0 p-0 text-decoration-underline"
              style={{ color: "black" }}
            >
              {t("footer.links.exchange")}
            </Link>
          </Nav>

          <Nav className="col-12 col-md-4 col-lg-2 d-flex flex-column align-items-center align-items-md-start mt-4 mt-md-0">
            <p className="m-0 fw-bold mb-3">
              {t("footer.categories.business")}
            </p>

            <Link
              to="/onramp"
              className="m-0 p-0 text-decoration-underline"
              style={{ color: "black" }}
            >
              {t("footer.links.onRamp")}
            </Link>
          </Nav>

          <Nav className="pt-4 pt-lg-0 col-12 col-md-4 col-lg-2 d-flex flex-column align-items-center align-items-md-start mt-4 mt-md-0">
            <p className="m-0 fw-bold mb-3">
              {t("footer.categories.policies")}
            </p>

            <Link
              to="/policy"
              className="m-0 p-0 text-decoration-underline"
              style={{ color: "black" }}
            >
              {t("footer.links.policy")}
            </Link>
            <Link
              to="/security"
              className="m-0 p-0 text-decoration-underline"
              style={{ color: "black" }}
            >
              {t("footer.links.security")}
            </Link>
            <Link
              to="/amlkyc"
              className="m-0 p-0 text-decoration-underline"
              style={{ color: "black" }}
            >
              {t("footer.links.amlkyc")}
            </Link>
            <Link
              to="/conditions"
              className="m-0 p-0 text-decoration-underline"
              style={{ color: "black" }}
            >
              {t("footer.links.conditions")}
            </Link>
          </Nav>

          <Nav className="pt-4 pt-lg-0 col-12 col-md-4 col-lg-2 d-flex flex-column align-items-center align-items-md-start mt-4 mt-md-0">
            <p className="m-0 fw-bold mb-3">{t("footer.categories.support")}</p>

            <Link
              to="/contactus"
              className="m-0 p-0 text-decoration-underline"
              style={{ color: "black" }}
            >
              {t("footer.links.contact")}
            </Link>
            <Link
              to="/faq"
              className="m-0 p-0 text-decoration-underline"
              style={{ color: "black" }}
            >
              {t("footer.links.faq")}
            </Link>
          </Nav>

          <div className="pt-4 pt-lg-0 col-12 col-md-4 col-lg-2 d-flex flex-column align-items-center align-items-md-start mt-4 mt-md-0">
            <p className="m-0 fw-bold mb-3">{t("footer.categories.follow")}</p>

            <Nav className="d-flex">
              <Link
                to="/"
                className="me-3 d-flex text-dark p-0"
                style={{ width: "max-content" }}
              >
                <svg
                  width="24"
                  height="24"
                  fill="currentColor"
                  focusable="false"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 1.715C6.3 1.715 1.714 6.3 1.714 12c0 5.142 3.772 9.385 8.657 10.157v-7.2H7.757V12h2.614V9.729c0-2.571 1.543-3.986 3.9-3.986 1.115 0 2.315.215 2.315.215v2.528H15.3c-1.286 0-1.671.772-1.671 1.586v1.929h2.87l-.47 2.957h-2.4v7.2c4.885-.772 8.657-5.015 8.657-10.157C22.286 6.3 17.7 1.715 12 1.715Z"></path>
                </svg>
              </Link>

              <Link
                to="/"
                className="me-3 d-flex text-dark p-0"
                style={{ width: "max-content" }}
              >
                <svg
                  width="24"
                  height="24"
                  fill="currentColor"
                  focusable="false"
                  viewBox="0 0 24 24"
                >
                  <path d="M20.186 7.995v.557c0 5.572-4.243 12-12 12-2.315 0-4.543-.643-6.472-1.886.343.043.686.043.986.043a8.585 8.585 0 0 0 5.229-1.8 4.238 4.238 0 0 1-3.943-2.914 3.992 3.992 0 0 0 1.885-.086c-1.971-.385-3.385-2.143-3.385-4.157V9.71c.6.343 1.243.515 1.928.515C2.571 8.98 1.971 6.538 3.13 4.609a11.937 11.937 0 0 0 8.7 4.415c-.343-1.458.128-3 1.2-4.029 1.714-1.586 4.37-1.5 5.957.171a7.996 7.996 0 0 0 2.7-1.028c-.3.986-.986 1.8-1.843 2.314.857-.086 1.671-.343 2.443-.643-.558.857-1.286 1.586-2.1 2.186Z"></path>
                </svg>
              </Link>

              <Link
                to="/"
                className="me-3 d-flex text-dark p-0"
                style={{ width: "max-content" }}
              >
                <svg
                  width="24"
                  height="24"
                  fill="currentColor"
                  focusable="false"
                  viewBox="0 0 24 24"
                >
                  <path
                    fillRule="evenodd"
                    d="M12 3.558c2.743 0 3.086 0 4.157.043.986.042 1.543.214 1.929.342.471.172.814.429 1.2.772.342.343.6.686.771 1.2.129.343.3.9.343 1.928.043 1.072.043 1.415.043 4.158 0 2.742 0 3.085-.043 4.157-.043.985-.214 1.543-.343 1.928-.171.472-.428.815-.771 1.2-.343.343-.686.6-1.2.772-.343.128-.9.3-1.929.343-1.071.042-1.414.042-4.157.042-2.743 0-3.086 0-4.157-.042-.986-.043-1.543-.215-1.929-.343-.471-.172-.814-.429-1.2-.772-.343-.343-.6-.685-.771-1.2-.129-.343-.3-.9-.343-1.928-.043-1.072-.043-1.415-.043-4.157 0-2.743 0-3.086.043-4.158.043-.985.214-1.542.343-1.928.171-.472.428-.814.771-1.2.343-.343.686-.6 1.2-.772.343-.128.9-.3 1.929-.342 1.071-.043 1.414-.043 4.157-.043Zm0-1.843c-2.786 0-3.129 0-4.243.043-1.114.043-1.843.214-2.486.471-.685.257-1.242.6-1.842 1.2a5.072 5.072 0 0 0-1.2 1.843c-.258.643-.43 1.414-.472 2.486-.043 1.114-.043 1.457-.043 4.243 0 2.785 0 3.128.043 4.242.043 1.115.214 1.843.472 2.486.257.686.6 1.243 1.2 1.843a5.073 5.073 0 0 0 1.842 1.2c.643.257 1.415.429 2.486.471 1.114.043 1.457.043 4.243.043 2.786 0 3.129 0 4.243-.043 1.114-.042 1.843-.214 2.486-.471.685-.257 1.242-.6 1.842-1.2a5.074 5.074 0 0 0 1.2-1.843c.258-.643.429-1.414.472-2.486.043-1.114.043-1.457.043-4.242 0-2.786 0-3.129-.043-4.243-.043-1.115-.214-1.843-.472-2.486-.257-.686-.6-1.243-1.2-1.843a5.072 5.072 0 0 0-1.843-1.2c-.642-.257-1.414-.428-2.485-.471-1.114-.043-1.457-.043-4.243-.043Zm0 5.014a5.268 5.268 0 0 0-5.271 5.27 5.268 5.268 0 0 0 5.27 5.272A5.268 5.268 0 0 0 17.272 12 5.268 5.268 0 0 0 12 6.729Zm0 8.7a3.439 3.439 0 0 1-3.429-3.43A3.439 3.439 0 0 1 12 8.572 3.439 3.439 0 0 1 15.428 12 3.439 3.439 0 0 1 12 15.428Zm6.729-8.915a1.243 1.243 0 1 1-2.486 0 1.243 1.243 0 0 1 2.486 0Z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </Link>
            </Nav>
          </div>
        </div>

        <div className="w-100 px-5 py-5 d-flex justify-content-between align-items-center">
          <div className="d-none d-md-flex">
            <img
              src={cornerLogoBlack}
              alt="cornerLogoBlack"
              style={{
                height: "50px",
              }}
            />
          </div>

          <p className="m-0 mx-auto mx-md-0" style={{ color: colorTextSecond }}>
            © CornerSwap Inc 2024
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
