import "./App.scss";
import { BrowserRouter } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { setLangIcon, setLangValue } from "./redux/slices/language.slice";
import Home from "./pages/Home/Home";
import { Blog } from "./pages/Blog";
import { SignIn } from "./pages/SignIn";
import ArticleMain from "./components/Blog/ArticleMain";
import BlogList from "./components/Blog/BlogList";
import { Page404 } from "./pages/Page404";
import { WaitList } from "./pages/WaitList";
import Dashboard from "./pages/Application/Dashboard";
import CryptoInfo from "./pages/CryptoInfo";
import { Business } from "./pages/Business";
import { OnRamp } from "./pages/OnRamp";
import { Registration } from "./pages/Registration";
import { Trade } from "./pages/Application/Trade";
import { Settings } from "./pages/Application/Settings";
import { Explore } from "./pages/Application/Explore";
import CryptoInfoApp from "./pages/Application/CryptoInfoApp";
import { ContactUs } from "./pages/Application/ContactUs";
import { Profile } from "./pages/Application/Profile";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import { Security } from "./pages/Security";
import { AmlKyc } from "./pages/AmlKyc";
import { TermsConditions } from "./pages/TermsConditions";
import { ContactHome } from "./pages/ContactUs";
import { FAQ } from "./pages/FAQ";
import { AboutUs } from "./pages/AboutUs";
import i18n from "./i18n/config";
import Form from "./pages/Application/Form";
import OrderHistory from "./pages/Application/OrderHistory";
import { ThemeProvider } from "./providers/ThemeProvider";
import { RecoveryPassword } from "./pages/RecoveryPassword"
import { NewPassword } from "./pages/NewPassword"

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const lang = localStorage.getItem("lng");
    const langIcon = localStorage.getItem("lngIcon");

    if (lang && langIcon && lang !== "en") {
      i18n.changeLanguage(lang);
      dispatch(setLangValue(lang));
      dispatch(setLangIcon(langIcon));
    }
  }, []);

  return (
    <ThemeProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/cryptoInfo/:cryptoName" element={<CryptoInfo />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<Registration />} />
          <Route path="/recovery" element={<RecoveryPassword />} />
          <Route path="/password/:token" element={<NewPassword />} />
          <Route path="/contactus" element={<ContactHome />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/policy" element={<PrivacyPolicy />} />
          <Route path="/security" element={<Security />} />
          <Route path="/amlkyc" element={<AmlKyc />} />
          <Route path="/conditions" element={<TermsConditions />} />
          <Route path="/business" element={<Business />} />
          <Route path="/onramp" element={<OnRamp />} />

          <Route path="/form/:token" element={<Form />} />

          <Route path="/home" element={<Dashboard />} />
          <Route path="/trade" element={<Trade />} />
          <Route path="//history" element={<OrderHistory />} />
          <Route path="/explore" element={<Explore />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/profile" element={<Profile />} />
          <Route
            path="/explore/cryptoInfo/:cryptoName"
            element={<CryptoInfoApp />}
          />

          {/* <Route path="/" element={<WaitList />} /> */}
          {/* 
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:categoryName" element={<BlogList />} />
        <Route path="/blog/:categoryName/:slug" element={<ArticleMain />} /> */}

          <Route path="/404" element={<Page404 />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
