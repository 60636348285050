import React, { useEffect } from "react";
import { Layout } from "../components/Layout";
import { ContactForm } from "../components/Application/ContactForm";

export const ContactHome = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <Layout>
      <div className="container py-5">
        <p className="contact__title">Contact us</p>

        <div className="w-100 border-bottom border-dark mb-5"></div>

        <ContactForm />
      </div>
    </Layout>
  );
};
