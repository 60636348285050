import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { Formik, Field, ErrorMessage } from "formik";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import cornerLogo from "../static/icons/CornerSwap_Logo.svg";
import step2Bg from "../static/images/Register/step2Bg.png";
import arrowBack from "../static/icons/signIn/arrowBack.svg";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { LanguagesBar } from "../components/Navbar/LanguagesBar";
import { RecoveryPasswordStep1 } from "../components/RecoveryPassword/RecoveryStep1";
import { RecoveryPasswordStep2 } from "../components/RecoveryPassword/RecoveryStep2"

export const RecoveryPassword = () => {
  const [stepRecovery, setStepRecovery] = useState(1)

  return (
    <div
      className="d-flex flex-column h-100 w-100 m-0 p-0 position-relative"
      style={{ minHeight: "100vh", minWidth: "100vw", background: "#252430" }}
    >
      {stepRecovery === 1 && <RecoveryPasswordStep1 setStepRecovery={setStepRecovery} />}

      {stepRecovery === 2 && <RecoveryPasswordStep2 />}
    </div>
  );
};
