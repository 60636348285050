import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  setListCryptocurrency,
  setOperationType,
} from "../../../redux/slices/order.slice";
import { BuyBodyTrade } from "./BuyBodyTrade";
import cornerLogo from "../../../static/icons/CornerSwapFinal.svg";
import { SellBodyTrade } from "./SellBodyTrade";
import { IRootState } from "../../../redux/store/store";
import { useTranslation } from "react-i18next"

const backendUrl = process.env.REACT_APP_PUBLIC_API_BACKEND;

export const currencies = [
  { maj: "EUR", min: "eur", quote: "ZEUR", name: "Euro", symbol: "€" },
  {
    maj: "USD",
    min: "usd",
    quote: "ZUSD",
    name: "United States Dollar",
    symbol: "$",
  },
  { maj: "JPY", min: "jpy", quote: "ZJPY", name: "Japanese Yen", symbol: "¥" },
  {
    maj: "AUD",
    min: "aud",
    quote: "ZAUD",
    name: "Australian Dollar",
    symbol: "$",
  },
  {
    maj: "CAD",
    min: "cad",
    quote: "ZCAD",
    name: "Canadian Dollar",
    symbol: "$",
  },
  { maj: "CHF", min: "chf", quote: "CHF", name: "Swiss Franc", symbol: "₣" },
];

export const ExchangeTrade = () => {
  const { t } = useTranslation();

  const { operationType } = useSelector(
    ({ orderData }: IRootState) => orderData
  );

  const dispatch = useDispatch();

  const handleListCrypto = (value: string = "ZEUR") => {
    const objectToPost = {
      input: value,
    };

    axios
      .post(`${backendUrl}/pb/pairsByDevice`, objectToPost)
      .then(
        (response: any) =>
          response.status === 200 &&
          dispatch(setListCryptocurrency(response?.data))
      )
      .catch(function (error: any) {
        console.log("error :", error);
      });
  };

  useEffect(() => {
    handleListCrypto();
  }, []);

  return (
    <div
      className="app__card rounded-4 h-100 d-flex flex-column justify-content-between"
    >
      <div className="px-3 py-2 py-xl-4 my-3 my-xl-2">
        <div className="d-flex align-items-center justify-content-between">
          <span className="app__trade-exchange--title">{t("app.trade.onRamp")}</span>

          <div
            className="rounded-5 d-flex justify-content-between align-items-center app__trade-exchange--options"
            style={{
              background: "#E8ECF5",
              padding: "2px",
            }}
          >
            <button
              className="fw-bold text-white rounded-5 p-0 border-0"
              style={{
                background: operationType === "Buy" ? "#D8AFF3" : "transparent",
                width: "48%",
              }}
              onClick={() => dispatch(setOperationType("Buy"))}
            >
              {t("app.home.buy")}
            </button>

            <button
              className="fw-bold text-white rounded-5 p-0 border-0"
              style={{
                background:
                  operationType === "Sell" ? "#D8AFF3" : "transparent",
                width: "48%",
              }}
              onClick={() => dispatch(setOperationType("Sell"))}
              disabled
            >
              {t("app.home.sell")}
            </button>
          </div>
        </div>

        {operationType === "Buy" ? (
          <BuyBodyTrade
            currencies={currencies}
            handleListCrypto={handleListCrypto}
          />
        ) : (
          <SellBodyTrade
            currencies={currencies}
            handleListCrypto={handleListCrypto}
          />
        )}
      </div>

      <div
        className="py-3 text-center"
        style={{
          fontSize: "10px",
          color: "#D4D4D4",
          borderTop: "2.6px solid #E9EDF5",
        }}
      >
        <span>{t("app.trade.powered")}</span>
        <img src={cornerLogo} alt="cornerLogo" height={15} />
        <span>{t("app.trade.registered")}</span>
      </div>
    </div>
  );
};
