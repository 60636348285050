import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IAuth {
  fetchAuth: {
    token: any;
    country: string;
    ClientId: any;
    lastName: any;
    firstName: any;
    isKyc: any;
    signature: any;

    isMail: any;
    isQcm: any;
    isPdata: any;

    checkCorner: any;
    phone: any;

    isSms: any;
    isNewsletter: any;

    totalFiatInvest: any;
    link_kyc: any;
    email: any;
    statut: any;
    kyc: any;
    wallets: any;
    wallet: any;
    walletType: any;
    historique: any;
    isFirstEntry: any;
  };

  isFirstEntry: any;
}

const initialState: IAuth = {
  fetchAuth: {
    country: "pl",
    token: null,
    ClientId: null,
    lastName: "Nom",
    firstName: "Prenom",
    isKyc: null,
    signature: null,

    isMail: null,
    isQcm: null,
    isPdata: null,

    checkCorner: null,
    phone: null,

    isSms: null,
    isNewsletter: null,

    totalFiatInvest: null,
    link_kyc: null,
    email: "email@email.com",
    statut: null,
    kyc: "",
    wallets: [],
    wallet: "",
    walletType: null,
    historique: [],
    isFirstEntry: null,
  },
  isFirstEntry: null,
};

type AuthEmail = PayloadAction<string>;
type Country = PayloadAction<string>;

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setEmail: (state, action: AuthEmail) => {
      state.fetchAuth.email = action.payload;
    },
    setCountry: (state, action: Country) => {
      state.fetchAuth.country = action.payload;
    },
    setAuth: (state, { payload }) => {
      // console.log("Payload: ", payload);
      state.fetchAuth = payload;
    },
    setSignature: (state, { payload }) => {
      // console.log("Payload: ", payload);
      state.fetchAuth.signature = payload;
    },
    setIsFirstEntry: (state, { payload }) => {
      // console.log("Payload: ", payload);
      state.isFirstEntry = payload;
    },
    setStatut: (state, { payload }) => {
      // console.log("Payload: ", payload);
      state.fetchAuth.statut = payload;
    },
    setKyc: (state, { payload }) => {
      // console.log("Payload: ", payload);
      state.fetchAuth.kyc = payload;
    },
    //@ts-ignore
    setInitiateCorer: (state, _) => {
      state.fetchAuth.ClientId = null;
      //@ts-ignore
      state.fetchAuth.lastName = null;
      //@ts-ignore
      state.fetchAuth.firstName = null;
      state.fetchAuth.isKyc = null;
      state.fetchAuth.signature = null;
      state.fetchAuth.checkCorner = null;
      state.fetchAuth.phone = null;
      state.fetchAuth.isMail = null;
      state.fetchAuth.isSms = null;
      state.fetchAuth.isNewsletter = null;
      state.fetchAuth.isQcm = null;
      state.fetchAuth.totalFiatInvest = null;
      //@ts-ignore
      state.fetchAuth.isFirstEntry = null;
      state.fetchAuth.kyc = "";
    },
    setLogOut: () => {
      // console.log("Payload: ", payload);
      return initialState;
    },
  },
});

export const {
  setEmail,
  setCountry,
  setAuth,
  setIsFirstEntry,
  setInitiateCorer,
  setStatut,
  setKyc,
  setSignature,
  setLogOut,
} = authSlice.actions;
export const authReducer = authSlice.reducer;
