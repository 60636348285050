import React from "react";
import Navbarr from "./Navbar/Navbar";
import Footer from "./Footer/Footer";
import { IRootState } from "../redux/store/store";
import { useSelector } from "react-redux";

export const Layout = ({ children }: any) => {
  const { colorGradient1, colorGradient2, navHomeHeigth } = useSelector(
    ({ styleData }: IRootState) => styleData
  );

  return (
    <div
      className="d-flex flex-column justify-content-between w-100 m-0 p-0 position-relative"
      style={{ minHeight: "100vh", height: "100%" }}
    >
      <div
        className="p-0 m-0 position-absolute h-100 top-0 start-0 w-100"
        style={{
          background: `linear-gradient(180deg, ${colorGradient1}, ${colorGradient2})`,
          zIndex: -2,
          backgroundSize: "200% 200%",
        }}
      />

      <Navbarr />

      <div
        className="w-100 m-0 p-0"
        style={{
          height: `${navHomeHeigth}px`,
        }}
      ></div>

      <div
        className="mx-auto w-100 m-0 p-0"
        style={{
          height: "100%",
          flex: "1",
          // background: `linear-gradient(180deg, ${colorGradient1}, ${colorGradient2})`,
          // zIndex: -2,
          // backgroundSize: "200% 200%",
        }}
      >
        {children}
      </div>

      <Footer />
    </div>
  );
};
