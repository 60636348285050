import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { Formik, Field } from "formik";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import cornerLogo from "../../static/icons/CornerSwap_Logo.svg";
import step2Bg from "../../static/images/Register/step2Bg.png";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { LanguagesBar } from "../Navbar/LanguagesBar";

interface IFormValide {
  password: string;
  passwordRepeat: string;
}

interface INewPasswordStep1 {
  setStepPassword: (value: number) => void;
}

const backendUrl = process.env.REACT_APP_PUBLIC_API_BACKEND;

export const NewPasswordStep1: React.FC<INewPasswordStep1> = ({
  setStepPassword,
}) => {
  const [visible, setVisible] = useState(false);
  const [visibleGlob, setVisibleGlob] = useState(false);
  const [typePassword, setTypePassword] = useState(true);
  const [typePasswordRep, setTypePasswordRep] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [alertSamePassword, setAlertSamePassword] = useState(false);

  const { token } = useParams();

  const navigate = useNavigate();

  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
      setVisibleGlob(true);
    }, 100);
  }, []);

  const schema = yup.object().shape({
    password: yup
      .string()
      .required()
      .min(8, "Mot de passe trop long")
      .test(
        "isValidPass",
        "The password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number, and one symbol ( !@#%& )",
        (value) => {
          const hasUpperCase = /[A-Z]/.test(value);
          const hasLowerCase = /[a-z]/.test(value);
          const hasNumber = /[0-9]/.test(value);
          const hasSymbole = /[!@#%&]/.test(value);
          let validConditions = 0;
          const numberOfMustBeValidConditions = 4;
          const conditions = [
            hasLowerCase,
            hasUpperCase,
            hasNumber,
            hasSymbole,
          ];
          conditions.forEach((condition) =>
            condition ? validConditions++ : null
          );

          return validConditions >= numberOfMustBeValidConditions;
        }
      ),
    passwordRepeat: yup
      .string()
      .oneOf([yup.ref("password")])
      .required(),
  });

  const ValideForm = (obj: IFormValide) => {
    setError(false);
    setLoading(true);
    setAlertSamePassword(false);

    axios
      .post(`${backendUrl}/users/password`, obj, {
        headers: {
          authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);

          setVisible(false);
          setVisibleGlob(false);

          setTimeout(() => {
            setStepPassword(2);
          }, 1000);
        }

        response.status === 400 && setAlertSamePassword(true);
      })
      .catch((error) => {
        setLoading(false);

        error.response.data.message === "same password" &&
          setAlertSamePassword(true);

        error.code === "ERR_NETWORK" && setError(true);
      });
  };

  const handleSingIn = () => {
    setVisible(false);
    setVisibleGlob(false);

    setTimeout(() => {
      navigate("/signin");
    }, 1000);
  };

  return (
    <>
      <div
        className="d-flex justify-content-end align-items-center py-2 py-lg-3 px-2 px-sm-4 px-lg-5 px-md-3"
        style={{
          borderBottom: "1px solid white",
          zIndex: 1,
          height: "60px",
          opacity: visible ? 1 : 0,
          transition: "all 1.5s",
        }}
      >
        <div className="d-flex align-items-center text-white signin--text-size">
          <LanguagesBar classProp="me-3" />

          <span>{t("register.step2.haveAcc")}</span>

          <button
            onClick={handleSingIn}
            className="text-white bg-transparent border-0 d-flex align-items-center ps-1 text-decoration-underline"
          >
            {t("register.step1.logIn")}
          </button>
        </div>
      </div>

      <div
        className="d-flex w-100 h-100 p-0 m-0"
        style={{
          flex: 1,
        }}
      >
        <div className="container d-flex flex-column pt-5 position-relative">
          <div
            className="h-100"
            style={{
              zIndex: 1,
              opacity: visible ? 1 : 0,
              transform: `scale(${visible ? 1 : 0.8})`,
              transition: "all 1.5s",
            }}
          >
            <div className="text-center d-none d-md-block">
              <img src={cornerLogo} alt="cornerLogo" height={100} />
            </div>

            <div className="text-center d-md-none">
              <img src={cornerLogo} alt="cornerLogo" height={70} />
            </div>

            <div>
              <div className="d-flex align-items-center justify-content-center mt-4 mt-md-5">
                <span className="fs-2 fw-semibold text-white">
                  {t("recovery.title")}
                </span>
              </div>

              <div className="col-11 col-md-9 col-lg-5 p-0 d-flex flex-column justify-content-center mx-auto mt-3">
                <Formik
                  validationSchema={schema}
                  onSubmit={ValideForm}
                  initialValues={{
                    password: "",
                    passwordRepeat: "",
                  }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    errors,
                  }) => (
                    <Form
                      className="my-3 text-center"
                      noValidate
                      onSubmit={handleSubmit}
                    >
                      <Row className="mb-3 mb-md-4 signin__button--size mx-auto">
                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="validationFormik03"
                          className="p-0"
                        >
                          <div className="position-relative d-flex">
                            <Field
                              className={`form-control signin--text-size px-4 py-2 py-sm-3 rounded-5 mb-0 pe-5 signin__button--size border ${
                                errors.password &&
                                touched.password &&
                                "border-danger"
                              }  ${!errors.password && touched.password && "border-success"}`}
                              style={{
                                boxShadow:
                                  "2px 2px 4px 0px rgba(0, 0, 0, 0.25)",
                                border: "1px solid transparent",
                                background: "#fff",
                              }}
                              id="password"
                              name="password"
                              value={values.password}
                              placeholder={t("logIn.password")}
                              type={typePassword ? "password" : "text"}
                              onChange={handleChange}
                            />
                            <span
                              className="signin__icon position-absolute top-50 end-0 translate-middle-y me-3"
                              style={{
                                backgroundColor: "transparent",
                                cursor: "pointer",
                              }}
                              onClick={() => setTypePassword(!typePassword)}
                            >
                              <img
                                src={
                                  typePassword
                                    ? "https://cdn1.iconfinder.com/data/icons/hawcons/32/699007-icon-21-eye-hidden-512.png"
                                    : "https://www.freeiconspng.com/thumbs/eye-icon/eyeball-icon-png-eye-icon-1.png"
                                }
                                alt="eye icon"
                                style={{
                                  width: "100%",
                                }}
                              />
                            </span>
                          </div>
                        </Form.Group>
                      </Row>

                      <Row className="mb-3 mb-md-4 signin__button--size mx-auto">
                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="validationFormik03"
                          className="p-0"
                        >
                          <div className="position-relative d-flex mb-3">
                            <Field
                              className={`form-control signin--text-size px-4 py-2 py-sm-3 rounded-5 mb-0 pe-5 signin__button--size ${
                                errors.passwordRepeat &&
                                touched.passwordRepeat &&
                                "border border-danger"
                              }  ${!errors.passwordRepeat && touched.passwordRepeat && "border border-success"}`}
                              style={{
                                boxShadow:
                                  "2px 2px 4px 0px rgba(0, 0, 0, 0.25)",
                                border: "1px solid transparent",
                                background: "#fff",
                              }}
                              id="passwordRepeat"
                              name="passwordRepeat"
                              value={values.passwordRepeat}
                              placeholder={t("register.step2.repeatPass")}
                              type={typePasswordRep ? "password" : "text"}
                              onChange={handleChange}
                            />
                            <span
                              className="signin__icon position-absolute top-50 end-0 translate-middle-y me-3"
                              style={{
                                backgroundColor: "transparent",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                setTypePasswordRep(!typePasswordRep)
                              }
                            >
                              <img
                                src={
                                  typePasswordRep
                                    ? "https://cdn1.iconfinder.com/data/icons/hawcons/32/699007-icon-21-eye-hidden-512.png"
                                    : "https://www.freeiconspng.com/thumbs/eye-icon/eyeball-icon-png-eye-icon-1.png"
                                }
                                alt="eye icon"
                                style={{
                                  width: "100%",
                                }}
                              />
                            </span>
                          </div>
                        </Form.Group>
                      </Row>

                      <Button
                        type="submit"
                        className="signin__button signin__button--size w-100 border-0 p-2 rounded-5"
                      >
                        {loading ? (
                          <Spinner
                            animation="border"
                            size="sm"
                            variant="light"
                          />
                        ) : (
                          t("register.step2.button")
                        )}
                      </Button>

                      {alertSamePassword && (
                        <p className="signin__button--size text-danger mt-2 mx-auto">
                          {t("recovery.identical")}
                        </p>
                      )}

                      {error && (
                        <p className="signin__button--size text-danger mt-2 mx-auto">
                          {t("recovery.error")}
                        </p>
                      )}
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>

          <div
            className="register__step2 d-flex align-items-center justify-content-center"
            style={{
              opacity: visibleGlob ? 1 : 0,
              transition: "all 1.5s",
            }}
          >
            <img
              src={step2Bg}
              alt="step2Bg"
              className="register__step2--image"
            />
          </div>
        </div>
      </div>
    </>
  );
};
