import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { BuyBody } from "./BuyBody";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setListCryptocurrency } from "../../redux/slices/order.slice";
import { IRootState } from "../../redux/store/store";

const backendUrl = process.env.REACT_APP_PUBLIC_API_BACKEND;

const currencies = [
  { maj: "EUR", min: "eur", quote: "ZEUR", name: "Euro", symbol: "€" },
  {
    maj: "USD",
    min: "usd",
    quote: "ZUSD",
    name: "United States Dollar",
    symbol: "$",
  },
  { maj: "JPY", min: "jpy", quote: "ZJPY", name: "Japanese Yen", symbol: "¥" },
  {
    maj: "AUD",
    min: "aud",
    quote: "ZAUD",
    name: "Australian Dollar",
    symbol: "$",
  },
  {
    maj: "CAD",
    min: "cad",
    quote: "ZCAD",
    name: "Canadian Dollar",
    symbol: "$",
  },
  { maj: "CHF", min: "chf", quote: "CHF", name: "Swiss Franc", symbol: "₣" },
];

const ExchangeCryptoBlock = () => {
  const { colorTextSecond } = useSelector(
    ({ styleData }: IRootState) => styleData
  );

  const dispatch = useDispatch();

  const handleListCrypto = (value: string = "ZEUR") => {
    const objectToPost = {
      input: value,
    };

    axios
      .post(`${backendUrl}/pb/pairsByDevice`, objectToPost)
      .then(
        (response: any) =>
          response.status === 200 &&
          dispatch(setListCryptocurrency(response?.data))
      )
      .catch(function (error: any) {
        console.log("error :", error);
      });
  };

  useEffect(() => {
    handleListCrypto();
  }, []);

  const { t } = useTranslation();

  return (
    <div
      className="exchangeDiv rounded-5 position-relative p-3"
      style={{ zIndex: 1, fontFamily: "Aeonic Pro" }}
    >
      <BuyBody currencies={currencies} handleListCrypto={handleListCrypto} />

      <Link
        to="/signin"
        className="col-12 mt-3 navbarCont__button fw-bold text-light text-decoration-none py-2 text-center rounded-5"
        style={{ background: "black" }}
      >
        Get started
      </Link>

      <p
        className="px-1 m-0 mt-1 pt-2 text-center lh-sm"
        style={{ color: colorTextSecond, fontSize: "12px" }}
      >
        Exchange rates are dynamic. Always remember to check the applicable rate
        in-app before carrying out your exchange
      </p>
    </div>
  );
};

export default ExchangeCryptoBlock;
