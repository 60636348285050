import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import businessPhone from "../../static/images/businessPhone.png";
import { useTranslation } from "react-i18next";

export const Banking = () => {
  const { t } = useTranslation();

  const [isVisible, setIsVisible] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const refs = useRef([
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
  ]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry, index) => {
          if (entry.isIntersecting) {
            setIsVisible((prevIsVisible) => {
              const newIsVisible = [...prevIsVisible];
              newIsVisible[index] = true;
              return newIsVisible;
            });
          }
        });
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    refs.current.forEach((ref) => {
      if (ref.current) {
        //@ts-ignore
        observer.observe(ref.current);
      }
    });

    return () => {
      refs.current.forEach((ref) => {
        if (ref.current) {
          //@ts-ignore
          observer.unobserve(ref.current);
        }
      });
    };
  }, [window.scrollY]);

  return (
    <div className="container mx-auto business__banking">
      <div className="row m-0 p-0 justify-content-between">
        <div
          className="col-12 col-lg-6 pe-lg-5 mb-5 mb-lg-0"
          style={{
            opacity: isVisible[0] ? 1 : 0,
            transition: "all 0.8s ease-in-out",
          }}
          //@ts-ignore
          ref={refs.current[0]}
        >
          <img
            src={businessPhone}
            alt="bankingImage"
            style={{ width: "100%" }}
          />
        </div>

        <div className="col-12 col-lg-6 position-relative d-md-grid gap-4 mt-md-5 mt-lg-0">
          <div
            className="d-none d-md-flex align-items-center business__banking-number position-absolute w-100"
            style={{
              opacity: isVisible[1] ? 1 : 0,
              transition: "all 0.8s ease-in-out",
            }}
            //@ts-ignore
            ref={refs.current[1]}
          >
            <div style={{ width: "210px" }}>01</div>

            <div
              className="ms-5"
              style={{
                flex: 1,
                borderTop: "3px solid #F5F5F5",
                zIndex: 2,
                height: "2px",
              }}
            ></div>
          </div>

          <span
            className="business__banking-sub"
            style={{
              opacity: isVisible[2] ? 1 : 0,
              transition: "all 0.8s ease-in-out",
            }}
            //@ts-ignore
            ref={refs.current[2]}
          >
            {t("business.banking.title.1")}
            <br />
            {t("business.banking.title.2")}
          </span>

          <p
            className="business__banking-title mt-3 m-lg-0 fw-bold"
            style={{
              opacity: isVisible[3] ? 1 : 0,
              transition: "all 0.8s ease-in-out",
              maxWidth: "400px",
            }}
            //@ts-ignore
            ref={refs.current[3]}
          >
            {t("business.banking.name")}
          </p>

          <p
            className="m-0"
            style={{
              color: "#6D6D6D",
              opacity: isVisible[4] ? 1 : 0,
              transition: "all 0.8s ease-in-out",
            }}
            //@ts-ignore
            ref={refs.current[4]}
          >
            {t("business.banking.sub")}
          </p>

          <div className="mt-4 mt-md-0 inter__500">
            <div
              className="d-flex"
              style={{
                color: "#6D6D6D",
                opacity: isVisible[5] ? 1 : 0,
                transition: "all 0.8s ease-in-out",
              }}
              //@ts-ignore
              ref={refs.current[5]}
            >
              <img
                src="https://assets-global.website-files.com/63c52465b53a441b76587e8f/6502ed9862068d67a76807bd_Check%20gradient%20orange.svg"
                alt="checkMark"
                width={24}
              />

              <span className="ms-3">{t("business.banking.step.1")}</span>
            </div>

            <div
              className="d-flex mt-3"
              style={{
                color: "#6D6D6D",
                opacity: isVisible[6] ? 1 : 0,
                transition: "all 0.8s ease-in-out",
              }}
              //@ts-ignore
              ref={refs.current[6]}
            >
              <img
                src="https://assets-global.website-files.com/63c52465b53a441b76587e8f/6502ed9862068d67a76807bd_Check%20gradient%20orange.svg"
                alt="checkMark"
                width={24}
              />

              <span className="ms-3">{t("business.banking.step.2")}</span>
            </div>

            <div
              className="d-flex mt-3"
              style={{
                color: "#6D6D6D",
                opacity: isVisible[7] ? 1 : 0,
                transition: "all 0.8s ease-in-out",
              }}
              //@ts-ignore
              ref={refs.current[7]}
            >
              <img
                src="https://assets-global.website-files.com/63c52465b53a441b76587e8f/6502ed9862068d67a76807bd_Check%20gradient%20orange.svg"
                alt="checkMark"
                width={24}
              />

              <span className="ms-3">{t("business.banking.step.3")}</span>
            </div>

            <div
              className="d-flex mt-3"
              style={{
                color: "#6D6D6D",
                opacity: isVisible[8] ? 1 : 0,
                transition: "all 0.8s ease-in-out",
              }}
              //@ts-ignore
              ref={refs.current[8]}
            >
              <img
                src="https://assets-global.website-files.com/63c52465b53a441b76587e8f/6502ed9862068d67a76807bd_Check%20gradient%20orange.svg"
                alt="checkMark"
                width={24}
              />

              <span className="ms-3">{t("business.banking.step.4")}</span>
            </div>
          </div>

          <div>
            <Link
              to="/business"
              className="text-white business__header-button rounded-5 d-flex py-3 mt-4 mt-md-0"
              style={{
                width: "max-content",
                opacity: isVisible[9] ? 1 : 0,
                transition: "all 0.8s ease-in-out",
              }}
              //@ts-ignore
              ref={refs.current[10]}
            >
              {t("business.header.button")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
